import React, { useState } from "react";
import { TextField, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { getUserFromLocalStorage } from "./../../service/localstorage";
import { getApiUrl } from "../../utils/getApiUrl";

const URL = getApiUrl();

function EditTestimonial({ onSuccess, currentData }) {
  const [formData, setFormData] = useState(currentData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const token = getUserFromLocalStorage()?.token;
  console.log(toast);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, bio, star_rating, comment } = formData;

    // Basic validation
    const errors = [];
    if (!name) errors.push("Name is required");
    if (!bio) errors.push("Bio is required");
    if (!comment) errors.push("Comment is required");
    if (!star_rating || star_rating < 1 || star_rating > 5)
      errors.push("Star rating must be between 1 and 5");

    if (errors.length) {
      const errorMessage = errors.join("\n"); // Join error messages with a line break
      toast.error(errorMessage); // Display single error message
      return;
    }

    try {
      const apiUrl = `${URL}/sitesettings/testimonials/${currentData?._id}`;
      const response = await axios.put(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.setItem("lastApiResponse", JSON.stringify(response.data));
      toast.success("Testimonial updated successfully!");
      onSuccess(response);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to update testimonial. Please try again.");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        Edit Testimonial
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="bio"
          label="Bio"
          name="bio"
          autoComplete="bio"
          onChange={handleChange}
          value={formData.bio}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="star_rating"
          label="Star Rating"
          name="star_rating"
          type="number"
          onChange={handleChange}
          value={formData.star_rating}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="comment"
          label="Comment"
          name="comment"
          autoComplete="comment"
          onChange={handleChange}
          value={formData.comment}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="mt-3"
        >
          Submit
        </Button>
      </form>
    </Container>
  );
}

export default EditTestimonial;
