import React, { useState, useEffect } from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { getUserFromLocalStorage } from "../../service/localstorage";
import { getApiUrl } from "../../utils/getApiUrl";

const URL = getApiUrl();
const apiKey = process.env.REACT_APP_API_KEY;

function AddTestimonials({ onSuccess }) {
  const [formData, setFormData] = useState({
    name: "",
    bio: "",
    star_rating: 3,
    comment: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, bio, star_rating, comment } = formData;

    // Basic validation
    const errors = [];
    if (!name) errors.push("Name is required");
    if (!bio) errors.push("Bio is required");
    if (!comment) errors.push("Comment is required");
    if (!star_rating || star_rating < 1 || star_rating > 5)
      errors.push("Star rating must be between 1 and 5");

    if (errors.length) {
      const errorMessage = errors.join("\n"); // Join error messages with a line break
      toast.error(errorMessage); // Display single error message
      return;
    }

    const user = getUserFromLocalStorage();
    const token = user?.token;
    const tokenType = user?.tokenType || "Bearer";

    try {
      const apiUrl = `${URL}/sitesettings/testimonials`;
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${tokenType} ${token}`,
        },
      });
      localStorage.setItem("lastApiResponse", JSON.stringify(response.data));
      toast.success("Testimonial added successfully!");
      onSuccess(response);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        Add Testimonial
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          onChange={handleChange}
          value={formData.name}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="bio"
          label="Bio"
          name="bio"
          autoComplete="bio"
          onChange={handleChange}
          value={formData.bio}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="star_rating"
          label="Star Rating"
          name="star_rating"
          type="number"
          onChange={handleChange}
          value={formData.star_rating}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="comment"
          label="Comment"
          name="comment"
          autoComplete="comment"
          onChange={handleChange}
          value={formData.comment}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="mt-3"
        >
          Submit
        </Button>
      </form>
    </Container>
  );
}

export default AddTestimonials;
