import React, { useState, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ImageIcon from "@mui/icons-material/Image";
import { getUserFromLocalStorage } from "../../service/localstorage";
const user = getUserFromLocalStorage();
const token = user?.token;
const tokenType = user?.tokenType || "Bearer";

function ImageUpload({ uploadUrl, onSuccess, onFailure, fieldName }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const inputRef = useRef();
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const onChooseFile = () => {
    inputRef.current.click();
  };

  const clearFileInput = () => {
    inputRef.current.value = "";
    setSelectedFile(null);
    setProgress(0);
    setUploadStatus("select");
  };

  const handleUpload = async () => {
    if (uploadStatus === "done") {
      clearFileInput();
      return;
    }
  
    try {
      setUploadStatus("uploading");
      const formData = new FormData();
      formData.append(fieldName, selectedFile);
  
      console.log("Selected file:", selectedFile);
      // console.log("Form data:", formData);
  
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          Authorization: `${tokenType} ${token}`, // Set the Authorization header
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });
  
      if (response) {
        onSuccess(response); // Callback for success
        toast.success("Image uploaded successfully ✅");
      }
  
      setUploadStatus("done");
    } catch (error) {
      console.error("Error uploading image:", error.response || error.message);
      onFailure(error); // Callback for failure
      setUploadStatus("select");
      toast.error("Failed to upload image. Please try again.");
    }
  };
  

  return (
    <div className=" mx-auto">
      <input
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />

      {!selectedFile && (
        <button className="file-btn" onClick={onChooseFile}>
          <span className="material-symbols-outlined">
            <CloudUploadIcon />
          </span>{" "}
        </button>
      )}

      {selectedFile && (
        <>
          <div className="file-card">
            <span className="material-symbols-outlined icon">
              <DescriptionIcon />
            </span>

            <div className="file-info">
              <div style={{ flex: 1 }}>
                <h6>{selectedFile?.name}</h6>

                <div className="progress-bg">
                  <div className="progress" style={{ width: `${progress}%` }} />
                </div>
              </div>

              {uploadStatus === "select" ? (
                <button onClick={clearFileInput}>
                  <span className="material-symbols-outlined close-icon">
                    <CloseIcon />
                  </span>
                </button>
              ) : (
                <div className="check-circle">
                  {uploadStatus === "uploading" ? (
                    `${progress}%`
                  ) : uploadStatus === "done" ? (
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "20px" }}
                    >
                      <CheckIcon />
                    </span>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <button className="upload-btn" onClick={handleUpload}>
            {uploadStatus === "select" || uploadStatus === "uploading"
              ? "Upload"
              : "Done"}
          </button>
        </>
      )}
    </div>
  );
}

export default ImageUpload;
