import React, { useState } from "react";
import {
  Button,
  TextField,
  Container,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { getApiUrl } from "../../utils/getApiUrl";
import { getUserFromLocalStorage } from "../../service/localstorage";
import toast from "react-hot-toast";

const ContactForm = ({ initialData, onContactFormSuccess }) => {
  const user = getUserFromLocalStorage();
  const token = user?.token;
  const [contactType, setContactType] = useState("phone");
  const [contactDetail, setContactDetail] = useState("");

  const updateContactDetails = async (event) => {
    event.preventDefault();
    let contactDetails;

    contactDetails = {
      contacts: [
        {
          type: contactType,
          detail: contactDetail,
        },
      ],
    };

    try {
      const response = await axios.post(
        `${getApiUrl()}/sitesettings/contacts`,
        contactDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          // withCredentials: true,
        }
      );

      if (response) {
        toast.success("Contact details updated successfully");
        setContactDetail("");
        onContactFormSuccess(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} style={{ padding: "16px", marginTop: "16px" }}>
        <Typography variant="h6" component="h1" gutterBottom>
          Update Contact Details
        </Typography>
        <form id="edit-form" onSubmit={updateContactDetails}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="contact-type-label">Contact Type</InputLabel>
            <Select
              labelId="contact-type-label"
              id="contact-type"
              value={contactType}
              onChange={(e) => setContactType(e.target.value)}
              label="Contact Type"
            >
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="facebook">Facebook</MenuItem>
              <MenuItem value="whatsapp">WhatsApp</MenuItem>
              <MenuItem value="linkedin">LinkedIn</MenuItem>
              <MenuItem value="twitter">Twitter</MenuItem>
              <MenuItem value="instagram">Instagram</MenuItem>
              <MenuItem value="telegram">Telegram</MenuItem>
              <MenuItem value="snapchat">Snapchat</MenuItem>
              <MenuItem value="youtube">YouTube</MenuItem>
              <MenuItem value="tiktok">TikTok</MenuItem>
              <MenuItem value="discord">Discord</MenuItem>
              <MenuItem value="pinterest">Pinterest</MenuItem>
              <MenuItem value="reddit">Reddit</MenuItem>
              <MenuItem value="skype">Skype</MenuItem>
              <MenuItem value="zoom">Zoom</MenuItem>
              <MenuItem value="google_meet">Google Meet</MenuItem>
              <MenuItem value="microsoft_teams">Microsoft Teams</MenuItem>
              <MenuItem value="website">Website</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="contact-detail"
            label="Contact Detail"
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder="For phone/email, use comma-separated values"
            value={contactDetail}
            onChange={(e) => setContactDetail(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="success"
            style={{ marginTop: "16px" }}
          >
            Update
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default ContactForm;
