import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { getUserFromLocalStorage } from "../../service/localstorage";
import { getApiUrl } from "../../utils/getApiUrl";

function OrganizationDetailsForm({ initialData, onDetailsSubmissionSuccess }) {
  const URL = getApiUrl();
  const apiKey = process.env.REACT_APP_API_KEY;

  // Initialize form data with initialData
  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState({});

  // Correctly handle changes to each input field
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  // Validate form data
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.address.trim()) newErrors.address = "Address is required.";
    if (!formData.website.trim()) newErrors.website = "Website is required.";
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    const user = getUserFromLocalStorage();
    const token = user?.token;
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      console.log("Form Data Submitted:", formData);
      try {
        const apiUrl = `${URL}/sitesettings/organization`;
        const response = await axios.post(apiUrl, formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          toast.success("Form submitted successfully.");
          onDetailsSubmissionSuccess(response);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Failed to submit form.");
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        marginTop: "10px",
      }}
    >
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        error={!!errors.name}
        helperText={errors.name}
        fullWidth
      />
      <TextField
        label="Address"
        name="address"
        value={formData.address}
        onChange={handleChange}
        error={!!errors.address}
        helperText={errors.address}
        fullWidth
      />
      <TextField
        label="Website"
        type="url"
        name="website"
        value={formData.website}
        onChange={handleChange}
        error={!!errors.website}
        helperText={errors.website}
        fullWidth
      />
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Submit
      </Button>
    </form>
  );
}

export default OrganizationDetailsForm;
