import {
  Box,
  Paper,
  useTheme,
  Typography,
  Grid,
  Button,
  Modal,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import axios from "axios";
import { getUserFromLocalStorage } from "../service/localstorage";
import { getApiUrl } from "../utils/getApiUrl";
function PaymentOptions() {
  const [qrImage, setQrImage] = useState(null);
  const [upiId, setUpiId] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fileInputRef = useRef(null);
  const URL = getApiUrl();
  const [previewImage, setPreviewImage] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [paymentDetailsModal, setPaymentDetailsModal] = useState(false);
  const key = process.env.REACT_APP_API_KEY;
  const user = getUserFromLocalStorage();
  const token = user?.token;
  const tokenType = user?.tokenType || "Bearer";

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(`${URL}/admin/qrcode`);
        if (response && response.data) {
          setPaymentDetails(response.data);
        }
      } catch (error) {
        console.error("Error fetching payment details:", error);
      }
    };

    fetchPaymentDetails();
  }, [URL]);

  const clearImage = () => {
    setPreviewImage(null);
    setQrImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleQrFileChange = (event) => {
    const file = event.target.files[0];
    setQrImage(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      setPreviewImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUploadQrImage = async () => {
    if (!qrImage) {
      toast.error("Please select a file first!", { duration: 3000 });
      return;
    }
    if (!upiId.trim()) {
      toast.error("Please enter UPI ID!", { duration: 3000 });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("file", qrImage);
    formData.append("upi_id", upiId.trim());

    try {
      const response = await axios.post(`${URL}/admin/qrcode`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenType} ${token}`,
        },
      });
      if (response.status === 200) {
        toast.success("Payment details uploaded successfully", { duration: 3000 });
        clearImage();
        setUpiId("");
        setPaymentDetailsModal(false);
        setPaymentDetails(response.data);
      } else {
        throw new Error("Failed to upload payment details");
      }
    } catch (error) {
      console.error("Error uploading QR image:", error);
      toast.error(error.response?.data?.message || "Failed to upload payment details", {
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box bgcolor={"#EEEEFF"} minHeight={"100vh"}>
      <Box
        sx={{
          [theme.breakpoints.up("xs")]: {
            marginLeft: "0px",
            padding: "10px 0px",
          },
          [theme.breakpoints.up("md")]: {
            marginLeft: "265px",
            padding: "10px 20px",
          },
        }}
      >
        <Paper
          sx={{
            margin: "0 auto",
            backgroundColor: "#F6F6F6",
            marginTop: "40px",
            padding: "20px",
            [theme.breakpoints.up("xs")]: {
              width: "310px",
            },
            [theme.breakpoints.up("md")]: {
              width: "700px",
            },
          }}
        >
          <Typography
            sx={{
              [theme.breakpoints.up("xs")]: {
                color: "#384D6C",
                fontWeight: "bold",
                fontSize: "18px",
              },
              [theme.breakpoints.up("md")]: {
                color: "#384D6C",
                fontWeight: "bold",
                fontSize: "22px",
              },
            }}
          >
            Payment Details
          </Typography>

          <Box className="payment-info-container mt-5">
            <Box className="box-1 mb-5">
              <Grid container spacing={{ xs: 2 }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className="justify-content-center d-flex align-items-center"
                >
                  <Typography
                    sx={{
                      [theme.breakpoints.up("xs")]: {
                        color: "#384D6C",
                        fontWeight: "bold",
                        fontSize: "18px",
                      },
                    }}
                  >
                    QR:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      position: "relative",
                      [theme.breakpoints.up("md")]: {
                        margin: "0 auto",
                        height: "250px",
                        width: "300px",
                      },
                      [theme.breakpoints.up("xs")]: {
                        width: "250px",
                        height: "210px",
                        margin: "5px",
                        margin: "0 auto",
                      },
                    }}
                  >
                    <Box className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                      <img
                        className="shadow-lg"
                        src={paymentDetails.qr_code_url}
                        alt="QR Code"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="box-2 mb-5">
              <Grid container spacing={{ xs: 2 }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className="justify-content-center d-flex align-items-center"
                >
                  <Typography
                    sx={{
                      [theme.breakpoints.up("xs")]: {
                        color: "#384D6C",
                        fontWeight: "bold",
                        fontSize: "18px",
                      },
                    }}
                  >
                    UPI ID:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box
                    className="d-flex justify-content-center align-items-center"
                    sx={{
                      width: "300px",
                      margin: "0 auto",
                      backgroundColor: "#DEDEDE",
                      borderRadius: "10px",
                      cursor: "pointer",
                      [theme.breakpoints.up("md")]: {
                        width: "300px",
                      },
                      [theme.breakpoints.up("xs")]: {
                        width: "250px",
                      },
                    }}
                  >
                    <input
                      type="text"
                      className="form-control py-2"
                      placeholder="Enter UPI ID"
                      value={paymentDetails.upi_id}
                      readOnly
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box className="box-2">
              <Grid container>
                <Grid item xs={0} md={4}></Grid>
                <Grid item xs={12} md={8}>
                  <Box
                    className="d-flex justify-content-center align-items-center"
                    sx={{
                      [theme.breakpoints.up("md")]: {
                        width: "300px",
                        margin: "0 auto",
                        cursor: "pointer",
                        padding: "0 5px",
                      },
                      [theme.breakpoints.up("xs")]: {
                        width: "260px",
                        margin: "0 auto",
                        cursor: "pointer",
                        padding: "0 5px",
                      },
                    }}
                  >
                    <Button
                      onClick={() => {
                        setPaymentDetailsModal(true);
                      }}
                      sx={{
                        [theme.breakpoints.up("xs")]: {
                          backgroundColor: "#384D6C",
                          color: "white",
                          width: "100%",
                          "&:hover": {
                            backgroundColor: "#384D6C",
                          },
                        },
                      }}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>

        <Modal
          onClose={() => setPaymentDetailsModal(false)}
          open={paymentDetailsModal}
        >
          <Box>
            <Paper
              sx={{
                [theme.breakpoints.up("xs")]: {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "#EEEEFF",
                  boxShadow: 24,
                  padding: "10px",
                  borderRadius: "8px",
                  width: "95%",
                },
                [theme.breakpoints.up("md")]: {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "#EEEEFF",
                  boxShadow: 24,
                  padding: "30px",
                  borderRadius: "8px",
                  width: "40%",
                },
              }}
            >
              <Typography
                sx={{
                  [theme.breakpoints.up("xs")]: {
                    color: "#384D6C",
                    fontWeight: "bold",
                    fontSize: "18px",
                  },
                  [theme.breakpoints.up("md")]: {
                    color: "#384D6C",
                    fontWeight: "bold",
                    fontSize: "22px",
                  },
                }}
              >
                Update Payment Details
              </Typography>

              <Box className="payment-info-container mt-5">
                <Box className="box-1 mb-5">
                  <Grid container spacing={{ xs: 2 }}>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className="justify-content-center d-flex align-items-center"
                    >
                      <Typography
                        sx={{
                          [theme.breakpoints.up("xs")]: {
                            color: "#384D6C",
                            fontWeight: "bold",
                            fontSize: "18px",
                          },
                        }}
                      >
                        QR:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Paper
                        onClick={handleFileInputClick}
                        sx={{
                          backgroundColor: "#DEDEDE",
                          borderRadius: "10px",
                          cursor: "pointer",
                          position: "relative",
                          [theme.breakpoints.up("md")]: {
                            margin: "0 auto",
                            height: "250px",
                            width: "300px",
                          },
                          [theme.breakpoints.up("xs")]: {
                            width: "250px",
                            height: "210px",
                            margin: "5px",
                            margin: "0 auto",
                          },
                        }}
                      >
                        <Box className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                          {previewImage ? (
                            <>
                              <DeleteIcon
                                onClick={clearImage}
                                fontSize="large"
                                style={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                  color: "red",
                                }}
                              />
                              <img
                                src={previewImage}
                                alt="Preview"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <CloudUploadIcon
                                fontSize="large"
                                sx={{ color: "#384D6C" }}
                              />
                              <input
                                type="file"
                                name="file"
                                onChange={handleQrFileChange}
                                ref={fileInputRef}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="box-2 mb-5">
                  <Grid container spacing={{ xs: 2 }}>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className="justify-content-center d-flex align-items-center"
                    >
                      <Typography
                        sx={{
                          [theme.breakpoints.up("xs")]: {
                            color: "#384D6C",
                            fontWeight: "bold",
                            fontSize: "18px",
                          },
                        }}
                      >
                        UPI ID:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box
                        className="d-flex justify-content-center align-items-center"
                        sx={{
                          width: "300px",
                          margin: "0 auto",
                          backgroundColor: "#DEDEDE",
                          borderRadius: "10px",
                          cursor: "pointer",
                          [theme.breakpoints.up("md")]: {
                            width: "300px",
                          },
                          [theme.breakpoints.up("xs")]: {
                            width: "250px",
                          },
                        }}
                      >
                        <input
                          type="text"
                          className="form-control py-2"
                          placeholder="Enter UPI ID"
                          value={upiId}
                          onChange={(e) => setUpiId(e.target.value)}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box className="box-2">
                  <Grid container>
                    <Grid item xs={0} md={4}></Grid>
                    <Grid item xs={12} md={8}>
                      <Box
                        className="d-flex justify-content-center align-items-center"
                        sx={{
                          [theme.breakpoints.up("md")]: {
                            width: "300px",
                            margin: "0 auto",
                            cursor: "pointer",
                            padding: "0 5px",
                          },
                          [theme.breakpoints.up("xs")]: {
                            width: "260px",
                            margin: "0 auto",
                            cursor: "pointer",
                            padding: "0 5px",
                          },
                        }}
                      >
                        <Button
                          onClick={handleUploadQrImage}
                          sx={{
                            [theme.breakpoints.up("xs")]: {
                              backgroundColor: "#384D6C",
                              color: "white",
                              width: "100%",
                              "&:hover": {
                                backgroundColor: "#384D6C",
                              },
                            },
                          }}
                          disabled={loading}
                        >
                          {loading ? "Uploading..." : "Upload"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default PaymentOptions;
