import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { getUserFromLocalStorage } from "../../service/localstorage";
import { getApiUrl } from "../../utils/getApiUrl";


const EditHostelLaws = ({ initialData, onlawsFormSuccess }) => {
  const [hostelLaws, setHostelLaws] = useState(initialData);
  const [message, setMessage] = useState("");
  const URL = getApiUrl();
  const apiKey = process.env.REACT_APP_API_KEY;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleInputChange = (e) => {
    setHostelLaws({
      ...hostelLaws,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = `${URL}/sitesettings/hostel/laws`;

    try {
      const user = getUserFromLocalStorage();
      const token = user?.token;
      const response = await axios.post(`${apiUrl}`, hostelLaws, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        onlawsFormSuccess(response);
      }
      setMessage(response.data.message);
      toast.success("Hostel laws updated successfully!");
    } catch (error) {
      setMessage(
        "Failed to update hostel laws: " +
          (error.response?.data?.detail || error.message)
      );
      toast.error("Failed to update hostel laws.");
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Card raised sx={{ mb: 4, borderRadius: 2 }}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Hostel Laws (English)"
              name="english"
              value={hostelLaws.english}
              onChange={handleInputChange}
              multiline
              rows={4}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Hostel Laws (Other Language)"
              name="other_language"
              value={hostelLaws.other_language}
              onChange={handleInputChange}
              multiline
              rows={4}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
      {message && (
        <Typography variant="body2" color="textSecondary" align="center">
          {message}
        </Typography>
      )}
    </Container>
  );
};

export default EditHostelLaws;
