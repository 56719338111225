import React from "react";
import { Box, Button, Grid } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const EnquiryCard = ({
  contact_no,
  created_at,
  email,
  full_name,
  hostel_location,
  message,
  seen,
  handelSeen,
  handleDelete,
}) => {
  const renderField = (label, value) => (
    <div className="d-flex justify-content-start align-items-center mt-3">
      <Box
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "14px", md: "16px" },
          color: "#989696",
          marginBottom: "0",
          width: "100px",
        }}
      >
        {label}
      </Box>
      <Box
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "12px", md: "16px" },
          color: "#384D6C",
          marginBottom: "0",
        }}
      >
        {value}
      </Box>
    </div>
  );

  const SeenButton = ({ seen, handelSeen }) => (
    <Box className="mt-3 text-center">
      <Button
        style={{
          width: 250,
          height: 35,
          backgroundColor: seen ? "#384D6C" : "#3F6C38",
          color: "white",
          border: "none",
          borderRadius: "10px",
          cursor: "pointer",
          fontSize: "15px",
          fontWeight: "650",
        }}
        onClick={handelSeen}
        type="button"
      >
        {seen ? "UNSEEN" : "SEEN"}
      </Button>
    </Box>
  );

  return (
    <Grid container mb={2} spacing={3} justifyContent="center">
      <Grid item xs={12} md={6}>
        <Box
          component="div"
          sx={{
            bgcolor: "#fff",
            padding: "14px 15px",
            border: "1px solid black",
            borderRadius: "10px",
            position: "relative",
          }}
        >
          <Box
            component="span"
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "#fff",
              bgcolor: "#384D6C",
              padding: "5px 10px",
              borderRadius: 12,
              margin: "10px 10px",
            }}
          >
            {created_at}
          </Box>
          <div className="p-2">
            {renderField("Name", full_name)}
            {renderField("Contact", contact_no)}
            {renderField("Email", email)}
            {renderField("Hostel", hostel_location)}
            {renderField("Message", message)}
            <SeenButton seen={seen} handelSeen={handelSeen} />
          </div>
          <div style={{ position: "absolute", top: "13px", right: "30px" }}>
            <DeleteForeverIcon
              fontSize="large"
              className="p-1 bg-danger text-light rounded-circle"
              style={{ cursor: "pointer" }}
              onClick={handleDelete} 
            />
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EnquiryCard;
