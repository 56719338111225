import React, { useState } from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { getApiUrl } from "../../utils/getApiUrl";


import { getUserFromLocalStorage } from "./../../service/localstorage"
function EditAddminStafDetails({ initialData  , onStaffEditSuccess}) {
  const [formData, setFormData] = useState(initialData);
  const URL = getApiUrl();
  const apiKey = process.env.REACT_APP_API_KEY;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const apiUrl = `${URL}/sitesettings/staff/${initialData.name}`;
    try {
      const user = getUserFromLocalStorage();
      const token = user?.token;
      const response = await axios.put(apiUrl, formData,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Form submitted:", response.data);
      onStaffEditSuccess(response);
      toast.success("Staff details updated successfully!");
    } catch (error) {
      console.error("Error updating form:", error);
      if (error.response) {
        const errorMessage =
          error.response.data.detail || "An unexpected error occurred.";
        toast.error(errorMessage);
      } else {
        toast.error("Network error or server unreachable.");
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        Edit {initialData?.name} Details
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        {/* <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          onChange={handleChange}
          value={formData.name}
        /> */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="position"
          label="Position"
          name="position"
          autoComplete="position"
          onChange={handleChange}
          value={formData.position}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="mobile_number"
          label="Mobile Number"
          name="mobile_number"
          autoComplete="mobile_number"
          onChange={handleChange}
          value={formData.mobile_number}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="whatsapp_number"
          label="WhatsApp Number"
          name="whatsapp_number"
          autoComplete="whatsapp_number"
          onChange={handleChange}
          value={formData.whatsapp_number}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          label="Email Address"
          name="email_address"
          autoComplete="email_address"
          onChange={handleChange}
          value={formData.email_address}
        />

        <Button
          className="mt-2"
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </form>
    </Container>
  );
}

export default EditAddminStafDetails;
