import React, { useState, useRef, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  useTheme,
  Paper,
  Button,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import Person2Icon from "@mui/icons-material/Person2";
import BadgeIcon from "@mui/icons-material/Badge";
import WcIcon from "@mui/icons-material/Wc";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import toast from "react-hot-toast";
import axios from "axios";
import RuleIcon from "@mui/icons-material/Rule";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getUserFromLocalStorage } from "./../service/localstorage";
import { getApiUrl } from "../utils/getApiUrl";
const AddNewStudent = () => {
  const URL = getApiUrl();
  const apikey = process.env.REACT_APP_API_KEY;
  const [additionalInformation, setAdditionalInformation] = useState({
    name: "",
    bio: "",
    dob: "",
    gender: "",
    contact_no: "",
    email: "",
    institute_name: "",
    fathers_name: "",
    fathers_occupation: "",
    mother_name: "",
    mothers_occupation: "",
    local_guardian_name: "",
    local_guardian_occupation: "",
    relation_local_guardian: "",
    permanent_address: "",
    city: "",
    state: "",
    address_building: "",
    password: "",
    verified: true,
  });
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const [previewImageProfile, setPreviewImageProfile] = useState(null);
  const [profileImg, setProfileImg] = useState("");
  const profileRef = useRef();

  const [previewImageAdharFront, setPreviewImageAdharFront] = useState(null);
  const [adharFrontImg, setAdharFrontImg] = useState("");
  const adharFrontRef = useRef();

  const [previewImageAdharBack, setPreviewImageAdharBack] = useState(null);
  const [adharBackImg, setAdharBackImg] = useState("");
  const adharBackRef = useRef();
  const [student, setStudent] = useState({});
  const genderOptions = ["Male", "Female", "Other"];
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const user = getUserFromLocalStorage();
  const token = user?.token;
  const tokenType = user?.tokenType || "Bearer";

  const clearProfileImage = () => {
    setPreviewImageProfile(null);
    setProfileImg("");
    if (profileRef.current) {
      profileRef.current.value = null;
    }
  };

  const clearAdharFrontImage = () => {
    setPreviewImageAdharFront(null);
    setAdharFrontImg("");
    if (adharFrontRef.current) {
      adharFrontRef.current.value = null;
    }
  };

  const clearAdharBackImage = () => {
    setPreviewImageAdharBack(null);
    setAdharBackImg("");
    if (adharBackRef.current) {
      adharBackRef.current.value = null;
    }
  };

  const handleClickForProfile = () => {
    if (profileRef.current) {
      profileRef.current.click();
    }
  };

  const handleClickForAdharFront = () => {
    if (adharFrontRef.current) {
      adharFrontRef.current.click();
    }
  };

  const handleClickForAdharBack = () => {
    if (adharBackRef.current) {
      adharBackRef.current.click();
    }
  };

  function profileFileHandler(e) {
    const file = e.target.files[0];
    if (!file) return;
    setProfileImg(file);
    const reader = new FileReader();
    reader.onload = function (event) {
      setPreviewImageProfile(event.target.result);
    };
    reader.readAsDataURL(file);
  }
  function adharFrontFileHandler(e) {
    const file = e.target.files[0];
    if (!file) return;
    setAdharFrontImg(file);
    const reader = new FileReader();
    reader.onload = function (event) {
      setPreviewImageAdharFront(event.target.result);
    };
    reader.readAsDataURL(file);
  }

  function adharBackFileHandler(e) {
    const file = e.target.files[0];
    if (!file) return;
    setAdharBackImg(file);
    const reader = new FileReader();
    reader.onload = function (event) {
      setPreviewImageAdharBack(event.target.result);
    };
    reader.readAsDataURL(file);
  }

  const uploadImg = async () => {
    // if (!profileImg) {
    //   toast.error("Please select a profile image!", 3000);
    //   setLoading(false);
    //   return;
    // }
    // if (!adharFrontImg) {
    //   setLoading(false);
    //   toast.error("Please select an Aadhar front image!", 3000);

    //   return;
    // }
    // if (!adharBackImg) {
    //   toast.error("Please select an Aadhar back image!", 3000);
    //   setLoading(false);

    //   return;
    // }

    const apiUrl1 = `${URL}/student/update_profile_image?student_id=${student.student_id}`;
    const apiUrl2 = `${URL}/student/update_aadharfront_image?student_id=${student.student_id}`;
    const apiUrl3 = `${URL}/student/update_aadharback_image?student_id=${student.student_id}`;

    const formData1 = new FormData();
    formData1.append("file", profileImg);

    const formData2 = new FormData();
    formData2.append("file", adharFrontImg);

    const formData3 = new FormData();
    formData3.append("file", adharBackImg);

    try {
      setLoading(true);
      const response1 = await axios.post(apiUrl1, formData1, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenType} ${token}`,
        },
      });
      if (response1.status === 200) {
        toast.success("Profile image uploaded successfully!", 3000);
      } else {
        toast.error("Failed to upload profile image. Please try again!", 3000);
        setLoading(false);
      }

      const response2 = await axios.post(apiUrl2, formData2, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenType} ${token}`,
        },
      });
      if (response2.status === 200) {
        toast.success("Aadhar front image uploaded successfully!", 3000);
      } else {
        toast.error(
          "Failed to upload Aadhar front image. Please try again!",
          3000
        );
        setLoading(false);
      }

      const response3 = await axios.post(apiUrl3, formData3, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenType} ${token}`,
        },
      });
      if (response3.status === 200) {
        toast.success("Aadhar back image uploaded successfully!", 3000);
      } else {
        toast.error(
          "Failed to upload Aadhar back image. Please try again!",
          3000
        );
        setLoading(false);
      }

      clearProfileImage();
      clearAdharFrontImage();
      clearAdharBackImage();
    } catch (error) {
      console.log("Error:", error.message);
      setLoading(false);
      // toast.error(
      //   "An error occurred while uploading images. Please try again later!",
      //   3000
      // );
    } finally {
      setLoading(false);
    }
  };

  setTimeout(() => {
    localStorage.removeItem("studentObject");
  }, 5000);
  const Handelchange = (event) => {
    const { name, value } = event.target;
    setAdditionalInformation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitHandler = async () => {
    const apiUrl = `${URL}/student`;
    const { name, email, password } = additionalInformation;

    setLoading(true);

    if (!name || !email || !password) {
      toast.error("Name , email and password are required fields!", {
        icon: "🔴",
        position: "top-right",
        autoClose: 3000,
      });
      setLoading(false);
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error("Please enter a valid email address!", {
        icon: "🔴",
        position: "top-right",
        autoClose: 3000,
      });
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(apiUrl, additionalInformation, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${tokenType} ${token}`,
        },
      });
      console.log(response);
      if (response) {
        toast.success(`${response.data.message}`, {
          icon: "🟢",
          position: "top-right",
          autoClose: 3000,
        });

        setAdditionalInformation({
          name: "",
          bio: "",
          dob: "",
          gender: "",
          contact_no: "",
          email: "",
          institute_name: "",
          fathers_name: "",
          fathers_occupation: "",
          mother_name: "",
          mothers_occupation: "",
          local_guardian_name: "",
          local_guardian_occupation: "",
          relation_local_guardian: "",
          permanent_address: "",
          city: "",
          state: "",
          address_building: "",
          password: "",
          verified: true,
        });
      }
      const data = response.data;
      const studentObject = JSON.stringify(data);
      localStorage.setItem("studentObject", studentObject);
    } catch (error) {
      console.log(error.message);
      toast.error("Email already exists.", {
        icon: "🔴",
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("studentObject"));
    if (data) {
      setStudent(data);
    }
  }, [loading]);
  return (
    <>
      <Box
        sx={{
          bgcolor: "#EEEEFF",
          width: "100%",
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.up("xs")]: {
              marginLeft: "0px",
              padding: "10px 0px",
            },
            [theme.breakpoints.up("md")]: {
              marginLeft: "265px",
              padding: "10px 10px",
            },
          }}
        >
          <Box className="py-4 px-2 ">
            <span
              className="container"
              style={{ fontSize: "20px", fontWeight: "700", color: "#384D6C" }}
            >
              Add New Student :
            </span>
          </Box>
          <Grid container>
            <Grid
              md={12}
              xs={12}
              className="d-flex justify-content-center align-items-center mt-1"
            >
              <Box style={{ padding: "10px" }}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="name"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Full Name :
                    </InputLabel>
                    <Input
                      fullWidth
                      id="name"
                      placeholder="Enter your full name"
                      name="name"
                      onChange={Handelchange}
                      value={additionalInformation.name}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <Person2Icon />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="bio"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Bio :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.bio}
                      id="bio"
                      name="bio"
                      placeholder="Enter your bio"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <BadgeIcon sx={{ margin: "0px" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="gender"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Gender :
                    </InputLabel>
                    <Select
                      onChange={Handelchange}
                      value={additionalInformation.gender}
                      name="gender"
                      id="gender"
                      placeholder="Select your gender"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <WcIcon sx={{ margin: "0px" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        // padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    >
                      {genderOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="phone"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Contact Number :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.contact_no}
                      id="phone"
                      placeholder="Enter contact number"
                      name="contact_no"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <PhoneEnabledIcon sx={{ margin: "0px" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="email"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Email Address :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.email}
                      id="email"
                      name="email"
                      placeholder="Enter your email address"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <EmailIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="password"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Enter Password :
                    </InputLabel>
                    <Input
                      type={!isPasswordShow ? "password" : "text"}
                      onChange={Handelchange}
                      value={additionalInformation.password}
                      id="password"
                      name="password"
                      placeholder="Enter Password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setIsPasswordShow((prev) => !prev);
                            }}
                          >
                            {isPasswordShow ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="verified"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Verified :
                    </InputLabel>
                    <Select
                      onChange={Handelchange}
                      value={String(additionalInformation.verified)}
                      name="verified"
                      id="verified"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <RuleIcon sx={{ margin: "0px" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        // padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="date-of-joinning"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Date of Birth :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.dob}
                      id="date-of-joinning"
                      name="dob"
                      placeholder="Enter date of joining"
                      type="date"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                        display: "flex",
                        margin: "0 auto",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box
            container
            sx={{
              border: "1px solid #D0D0D0",
              width: "90%",
              margin: "25px auto",
            }}
          ></Box>
          <Box
            className="additional-info-containe"
            sx={{
              [theme.breakpoints.up("xs")]: {
                width: "100%",
                padding: "0 0px",
              },
              [theme.breakpoints.up("md")]: {
                width: "90%",
                padding: "0 30px",
                margin: "20px auto",
              },
            }}
          >
            <Box className="d-flex justify-content-between">
              <Box
                className=""
                sx={{
                  fontWeight: "700",
                  color: "#384D6C",
                  fontSize: "20px",
                  [theme.breakpoints.up("xs")]: {
                    fontSize: "18px",
                    marginLeft: "10px",
                  },
                  [theme.breakpoints.up("md")]: {
                    fontSize: "18px",
                    marginLeft: "10px",
                  },
                }}
              >
                Additional Information :{" "}
              </Box>
              <Box className=""></Box>
            </Box>
            <form>
              <Box style={{ padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="building"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Building :
                    </InputLabel>
                    <Input
                      id="building"
                      name="address_building"
                      onChange={Handelchange}
                      value={additionalInformation.address_building}
                      placeholder="Enter your building name"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="institute"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Institute Name :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.institute_name}
                      id="institute"
                      name="institute_name"
                      placeholder="Enter your institution name"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="father_name"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Father’s Name :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.fathers_name}
                      id="father_name"
                      name="fathers_name"
                      placeholder="Enter your father name"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="father_occupation"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Father’s Occupation :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.fathers_occupation}
                      id="father_occupation"
                      name="fathers_occupation"
                      placeholder="Enter your father's occupation"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="local_guardian_name"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Local Guardian Name :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.local_guardian_name}
                      id="local_guardian_name"
                      name="local_guardian_name"
                      placeholder="Enter local guardian name"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="local_guardian_occupation"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Local Guardian Occupation :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.local_guardian_occupation}
                      id="local_guardian_occupation"
                      name="local_guardian_occupation"
                      placeholder="Enter local guardian occupation"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="mother_name"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Mother’s Name :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.mother_name}
                      id="mother_name"
                      name="mother_name"
                      placeholder="Enter your father's occupation"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="mother_occupation"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Mother’s Occupation :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.mothers_occupation}
                      id="mother_occupation"
                      name="mothers_occupation"
                      placeholder="Enter mother's occupation"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="permanent_address"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Permanent Address :
                    </InputLabel>
                    <Input
                      id="permanent_address"
                      name="permanent_address"
                      onChange={Handelchange}
                      value={additionalInformation.permanent_address}
                      placeholder="Enter your permanent address"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                </Grid>{" "}
                <Grid container spacing={2} mt={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="local_guardian_relation"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Relation with Local Guardian :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.relation_local_guardian}
                      id="local_guardian_relation"
                      name="relation_local_guardian"
                      placeholder="Enter relation with local guardian"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="state"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      State :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.state}
                      id="state"
                      name="state"
                      placeholder="Enter your state"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="city"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      City :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.city}
                      id="city"
                      name="city"
                      placeholder="Enter your city"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
          <Box
            container
            sx={{
              border: "1px solid #D0D0D0",
              width: "90%",
              margin: "25px auto",
            }}
          ></Box>

          <Grid container justifyContent={"center"} spacing={0}>
            <Grid item md={3} xs={6} className="d-flex justify-content-center">
              <button
                style={{
                  backgroundColor: loading ? "gray" : "#384D6C",
                  border: "1px solid #D1D5DB",
                  borderRadius: "8px",
                  fontWeight: "700",
                  color: "#ffff",
                  width: "160px",
                  padding: "10px 10px",
                }}
                disabled={loading}
                onClick={submitHandler}
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </Grid>
          </Grid>
          <Box
            container
            sx={{
              border: "1px solid #D0D0D0",
              width: "90%",
              margin: "25px auto",
            }}
          ></Box>
          <Box
            className=""
            sx={{
              fontWeight: "700",
              color: "#384D6C",
              fontSize: "20px",
              [theme.breakpoints.up("xs")]: {
                fontSize: "18px",
                marginLeft: "10px",
              },
              [theme.breakpoints.up("md")]: {
                fontSize: "18px",
                marginLeft: "80px",
              },
            }}
          >
            Upload KYC :
          </Box>
          <Box className="mt-4 px-5">
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" className="text-center mb-3">
                  Profile Upload
                </Typography>
                <Paper
                  onClick={handleClickForProfile}
                  sx={{
                    backgroundColor: "#DEDEDE",
                    borderRadius: "10px",
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                    height: "250px",
                  }}
                >
                  <Box className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                    {previewImageProfile ? (
                      <>
                        <DeleteIcon
                          onClick={clearProfileImage}
                          fontSize="large"
                          style={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            color: "red",
                          }}
                        />
                        <img
                          src={previewImageProfile}
                          alt="Preview"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <CloudUploadIcon
                          fontSize="large"
                          style={{ color: "#384D6C" }}
                        />
                        <input
                          type="file"
                          name="file"
                          onChange={profileFileHandler}
                          ref={profileRef}
                          style={{ display: "none" }}
                        />
                      </>
                    )}
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="h6" className="text-center mb-3">
                  Aadhar{" "}
                  <span
                    style={{
                      backgroundColor: "yellow",
                      textDecoration: "underline",
                    }}
                  >
                    Front
                  </span>
                </Typography>
                <Paper
                  onClick={handleClickForAdharFront}
                  sx={{
                    backgroundColor: "#DEDEDE",
                    borderRadius: "10px",
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                    height: "250px",
                  }}
                >
                  <Box className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                    {previewImageAdharFront ? (
                      <>
                        <DeleteIcon
                          onClick={clearAdharFrontImage}
                          fontSize="large"
                          style={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            color: "red",
                          }}
                        />
                        <img
                          src={previewImageAdharFront}
                          alt="Preview"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <CloudUploadIcon
                          fontSize="large"
                          style={{ color: "#384D6C" }}
                        />
                        <input
                          type="file"
                          name="file"
                          onChange={adharFrontFileHandler}
                          ref={adharFrontRef}
                          style={{ display: "none" }}
                        />
                      </>
                    )}
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="h6" className="text-center mb-3">
                  Aadhar{" "}
                  <span
                    style={{
                      backgroundColor: "yellow",
                      textDecoration: "underline",
                    }}
                  >
                    Back
                  </span>
                </Typography>
                <Paper
                  onClick={handleClickForAdharBack}
                  sx={{
                    backgroundColor: "#DEDEDE",
                    borderRadius: "10px",
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                    height: "250px",
                  }}
                >
                  <Box className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                    {previewImageAdharBack ? (
                      <>
                        <DeleteIcon
                          onClick={clearAdharBackImage}
                          fontSize="large"
                          style={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            color: "red",
                          }}
                        />
                        <img
                          src={previewImageAdharBack}
                          alt="Preview"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <CloudUploadIcon
                          fontSize="large"
                          style={{ color: "#384D6C" }}
                        />
                        <input
                          type="file"
                          name="file"
                          onChange={adharBackFileHandler}
                          ref={adharBackRef}
                          style={{ display: "none" }}
                        />
                      </>
                    )}
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12}>
                <Box className="d-flex justify-content-center">
                  <Button
                    style={{
                      backgroundColor: loading ? "gray" : "#384D6C",
                      border: "1px solid #D1D5DB",
                      borderRadius: "8px",
                      fontWeight: "700",
                      color: "#ffff",
                      width: "160px",
                      padding: "10px 10px",
                    }}
                    onClick={uploadImg}
                    variant="contained"
                    className="m-2"
                    disabled={loading}
                  >
                    {loading ? "uploading..." : "Upload"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* <ToastContainer /> */}
    </>
  );
};

export default AddNewStudent;
