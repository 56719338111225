import React, { useEffect, useState } from "react";
import {
  Link as RouterLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";

// Material UI components
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Material UI icons
import AddBoxIcon from "@mui/icons-material/AddBox";
import ReviewsIcon from "@mui/icons-material/Reviews";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LockClockIcon from "@mui/icons-material/LockClock";
import MenuIcon from "@mui/icons-material/Menu";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import SwipeVerticalIcon from "@mui/icons-material/SwipeVertical";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import logo from "./../../assets/img/logonarayana.png";
import {
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
  isValidToken
} from "../../service/localstorage";
import GoBackButton from "../GoBackButton/GoBackButton";
import { getApiUrl } from "../../utils/getApiUrl";
const URL = getApiUrl();
const drawerWidth = 265;

function SideBar(props) {
  const { window } = props;
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [user, setUser] = useState({});
  const [logoImg, setLogoImg] = useState(null);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const URL = getApiUrl();

  useEffect(() => {
    const checkTokenValidity = async () => {
      const validation = await isValidToken();
     if (!validation.isValid) {
        localStorage.removeItem("user");

        navigate("/login");
        localStorage.removeItem("user");

        alert("Your session has been expired");
      }
    };

    checkTokenValidity();
  }, []);

  useEffect(() => {
    const fetchUser = () => {
      const user = getUserFromLocalStorage();
      setUser(user);
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const getOrganizationDetails = async () => {
      const apiUrl = `${URL}/sitesettings/organization`;
      try {
        const response = await axios.get(apiUrl);
        if (response.status===200) {
          setLogoImg(response.data.logo_url);
          // console.log("Logo URL:", response); // Add this line to check the URL
        } else {
          console.log("Unexpected response status:", response.data.status);
        }
      } catch (error) {
        console.log("Error:", error.message);
      }
    };

    getOrganizationDetails();
  }, [URL]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleLogout = () => {
    removeUserFromLocalStorage();
    toast.success("Logged out successfully");
    setTimeout(() => {
      navigate("/login");
    }, 2000);
  };

  const routes = [
    { path: "/", name: "Dashboard", icon: <DashboardIcon /> },
    {
      path: "/roomallocation",
      name: "Room Allocation",
      icon: <RoomPreferencesIcon />,
    },
    { path: "/students", name: "Students", icon: <AssignmentIndIcon /> },
    {
      path: "/roomallocationhistory",
      name: "Allocation History",
      icon: <WorkHistoryIcon />,
    },
    {
      path: "/updatepassword",
      name: "Update Password",
      icon: <LockClockIcon />,
    },
    { path: "/enquirys", name: "Enquiries", icon: <ContactMailIcon /> },
    {
      path: "/payment-option",
      name: "Payment Option",
      icon: <AccountBalanceIcon />,
    },
    { path: "/add-student", name: "Add Student", icon: <AddBoxIcon /> },
    {
      path: "/direct-allocation",
      name: "Direct Allocation",
      icon: <SwipeVerticalIcon />,
    },
    {
      path: "/profile/site-setting",
      name: "Site Settings",
      icon: <RoomPreferencesIcon />,
    },
    {
      path: "/testimonials",
      name: "Testimonials",
      icon: <ReviewsIcon />,
    },
  ];

  return (
    <Box>
      <AppBar position="fixed" sx={{ background: "#fff" }}>
        <Toolbar sx={{position:"relative"}}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 1, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Avatar
            src={logoImg || "path_to_default_logo"}
            alt="Logo"
            sx={{ ml: "auto" }}
          />
          <Button
            sx={{ marginLeft: "20px" }}
            onClick={handleLogout}
            variant="contained"
            color="error"
          >
            Logout
          </Button>
        <Box sx={{position:"absolute", left:{xs:"40px",md:"280px"}}}><GoBackButton /></Box>  
        </Toolbar>

      </AppBar>
      <Drawer
        container={
          window !== undefined ? () => window().document.body : undefined
        }
        variant={isMobile ? "temporary" : "permanent"}
        anchor="left"
        open={open}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          ...(isMobile && {
            "& .MuiDrawer-paperAnchorLeft": { width: drawerWidth },
          }),
        }}
      >
        <Avatar
          alt="Remy Sharp"
          src={logoImg}
          sx={{
            width: 120,
            height: 120,
            margin: "20px auto",
            borderRadius: "10px",
          }}
        />
        <List>
          {routes.map((item, index) => (
            <ListItemButton
              key={index}
              component={RouterLink}
              to={item.path}
              onClick={() => handleItemClick(item)}
              sx={{
                backgroundColor:
                  location.pathname === item.path ? "#bab0f5" : "transparent",
                "&:hover": {
                  backgroundColor: "#dcd6ff",
                },
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 0,
          marginLeft: { sm: open ? `${drawerWidth}px` : 0 },
          transition: "margin-left 0.3s ease-in-out",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default SideBar;
