import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Paper, Popover, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const buttonStyle = {
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0.5px",
  padding: "1px 12px",
  borderRadius: "5px",
  color: "#4d76ff",
  fontWeight: "bold",
};

const GoBackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          justifyContent: "center",
        }}
      >
        <Box
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          variant="contained"
          onClick={goBack}
          style={buttonStyle}
        >
          <KeyboardBackspaceIcon />
        </Box>
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none", mt: 1 }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>
          Go back to previous page
          <br />
        </Typography>
      </Popover>
    </>
  );
};

export default GoBackButton;
