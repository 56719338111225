import { Box, Button, Input, Typography, InputLabel } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { getUserFromLocalStorage } from "../service/localstorage";
import { getApiUrl } from "../utils/getApiUrl";

function UpdatePassword() {
  const [studentDetails, setStudentDetails] = useState({
    email: "",
    password: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const theme = useTheme();
  const user = getUserFromLocalStorage();
  const token = user?.token;
  const tokenType = user?.tokenType || "Bearer";
  const URL = getApiUrl();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudentDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateInput = () => {
    if (!studentDetails.email) {
      toast.error("Email is required");
      return false;
    }
    if (!studentDetails.password) {
      toast.error("Password is required");
      return false;
    }
    if (!confirmPassword) {
      toast.error("Confirm password is required");
      return false;
    }
    if (studentDetails.password !== confirmPassword) {
      toast.error("Passwords do not match");
      return false;
    }
    return true;
  };

  const resetPassword = async () => {
    if (!validateInput()) {
      return;
    }

    const url = `${URL}/student/update-password`;

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${tokenType} ${token}`,
        },
      };
      const response = await axios.patch(url, studentDetails, config);
      if (response.status === 200) {
        toast.success(response.data.message);
        setStudentDetails({ email: "", password: "" });
        setConfirmPassword("");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update password");
      console.error("Error updating password:", error);
    }
  };

  return (
    <>
      <Box bgcolor={"#EEEEFF"} minHeight={"100vh"}>
        <Box
          sx={{
            [theme.breakpoints.up("xs")]: {
              marginLeft: "0px",
              padding: "10px 0px",
            },
            [theme.breakpoints.up("md")]: {
              marginLeft: "265px",
              padding: "10px 10px",
            },
          }}
        >
          <Box>
            <Typography
              variant="h6"
              className="mt-3"
              sx={{ color: "#384D6C", marginLeft: { xs: "20px", md: "20px" } }}
            >
              Update Password :
            </Typography>
          </Box>

          <Box
            sx={{
              border: "1px solid  #B3B3B3",
              borderRadius: 5,
              padding: 4,
              width: { xs: 340, md: 600 },
              margin: "50px auto",
            }}
          >
            <Box mb={3}>
              <Typography
                className="container"
                sx={{
                  fontSize: { xs: 16, md: 20 },
                  fontWeight: "700",
                  bgcolor: "#384D6C",
                  textAlign: "center",
                  width: "fit-content",
                  marginX: "auto",
                  color: "#fff",
                  padding: "5px 20px",
                  borderRadius: 3,
                }}
              >
                Update Student Password
              </Typography>
            </Box>

            <Box className="form-container">
              <Box mb={3}>
                <InputLabel htmlFor="email" className="mb-2 fw-bold">
                  Email
                </InputLabel>
                <Input
                  id="email"
                  placeholder="Enter Student Email"
                  name="email"
                  className="form-control"
                  onChange={handleChange}
                  value={studentDetails.email}
                  fullWidth
                />
              </Box>

              <Box mb={3}>
                <InputLabel htmlFor="password" className="mb-2 fw-bold">
                  New Password
                </InputLabel>
                <Input
                  id="password"
                  placeholder="Enter New Password"
                  name="password"
                  className="form-control"
                  onChange={handleChange}
                  value={studentDetails.password}
                  type="password"
                  fullWidth
                />
              </Box>

              <Box mb={3}>
                <InputLabel htmlFor="confirmPassword" className="mb-2 fw-bold">
                  Confirm New Password
                </InputLabel>
                <Input
                  id="confirmPassword"
                  placeholder="Confirm New Password"
                  className="form-control"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                />
              </Box>

              <Button
                sx={{
                  backgroundColor: "#384D6C",
                  border: "1px solid #D1D5DB",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: 3,
                  margin: "0 auto",
                  display: "block",
                  fontSize: { xs: 16, md: 18 },
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#384D6C" },
                }}
                onClick={resetPassword}
              >
                RESET PASSWORD
              </Button>
            </Box>
          </Box>
        </Box>
        <Toaster />
      </Box>
    </>
  );
}

export default UpdatePassword;
