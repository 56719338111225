import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import loginGif from "../../assets/img/login.gif";
import { useAuth } from "../../context/User";
import toast, { Toaster } from "react-hot-toast";
import adminIcon from "../../assets/img/logonarayana.png";
import qs from "qs";
import { addUserToLocalStorage } from "../../service/localstorage";
import { getApiUrl } from "../../utils/getApiUrl";

const defaultTheme = createTheme();
const URL = getApiUrl();

export default function SignInSide() {
  const [auth, setAuth] = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [organizationDetails, setOrganizationDetails] = useState({});

  const fetchOrganizationDetails = async () => {
    const response = await axios.get(`${URL}/sitesettings/organization`);
    return response.data;
  };

  const fetchData = async () => {
    try {
      console.log("Fetching organization details...");
      const organizationDetails = await fetchOrganizationDetails();
      console.log(
        "Organization details fetched successfully:",
        organizationDetails
      );

      setOrganizationDetails(organizationDetails);
    } catch (error) {
      console.error("Failed to fetch data", error);
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      username: email,
      password: password,
    };

    const formData = qs.stringify(data);

    try {
      const res = await axios.post(`${URL}/api/login`, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (res?.data?.access_token) {
        toast.success("Login Successfully");

        const authData = {
          token: res.data.access_token,
          tokenType: res.data.token_type,
        };

        addUserToLocalStorage(authData);

        setAuth({
          token: res.data.access_token,
          tokenType: res.data.token_type,
        });

        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      } else {
        toast.error("Login failed. Please try again.");
      }
    } catch (error) {
      // console.error("Error during login:", error)  ;
      if (
        error.response.status === 403 &&
        error.response.data.detail === "Invalid Credentials"
      ) {
        toast.error(`Error: ${error.response.data.detail}`);
      }

      if (error.response && error.response.data && error.response.data.detail) {
        const errorMsgs = error.response.data.detail
          .map((err) => `${err.loc[1]}: ${err.msg}`)
          .join(", ");
        toast.error(`Error: ${errorMsgs}`);
      } else {
        toast.error("Login failed. Please try again.");
      }
    }
  };

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${loginGif})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{ m: 1, bgcolor: "secondary.main", width: 50, height: 50 }}
                src={organizationDetails?.logo_url}
              />
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      <Toaster />
    </>
  );
}
