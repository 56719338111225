import {
  Box,
  Button,
  Divider,
  FormControl,
  ListItemText,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import PauseIcon from "@mui/icons-material/Pause";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import toast from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "../styles/RecieptPdf.css";
import { getApiUrl } from "../utils/getApiUrl";
import { getUserFromLocalStorage } from "../service/localstorage";
function RoomAllocationHistory() {
  const theme = useTheme();
  const [RoomAllocationHistory, setRoomAllocationHistory] = useState([]);
  const [student, setStudent] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [status, setStatus] = useState("all");
  const [search, setSearch] = useState("");
  const URL = getApiUrl();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // useRef hook to create a reference to the receipt div
  const receiptRef = useRef(null);
  const [receiptData, setReceiptData] = useState({});
  const [signature, setSignatureObject] = useState({});
  const [organization, setOrganization] = useState({});
  const [contacts, setContacts] = useState([]);
  const [curentOrderId, setCurentOrderId] = useState("");
  const [isPrintReady, setIsPrintReady] = useState(false);

  const user = getUserFromLocalStorage();
  const token = user?.token;

  // const handleTogglePrintReady = () => {
  //   setIsPrintReady(!isPrintReady);
  // };

  function getContactDetailsinString(data) {
    for (const item of data) {
      if (item.type === "phone") {
        // Verify item.detail is an array
        if (Array.isArray(item.detail)) {
          // Log the original detail array

          const cleanedDetails = item.detail.map((number) => {
            // Clean each number and log the cleaned number
            const cleanedNumber = number.replace(/[^\d+]/g, "");
            return cleanedNumber;
          });

          // Join numbers with a newline and log the final result
          const result = cleanedDetails.join(`\n`);
          // console.log("Final joined result:", result);
          return result;
        }
      }
    }
    return;
  }

  const contactDetailsAsString = getContactDetailsinString(contacts);
  console.log(contacts);
  const getAdminOrganazationDetails = async () => {
    const apiOrgUrl = `${URL}/sitesettings/organization`;
    const apiCntUrl = `${URL}/sitesettings/contacts`;
    const apiSignUrl = `${URL}/admin/signature`;
    try {
      const response1 = await axios.get(apiOrgUrl);
      const response2 = await axios.get(apiCntUrl);
      const response3 = await axios.get(apiSignUrl);
      if (response1 && response1.data) {
        setOrganization(response1?.data);
        // console.warn(response1?.data);
      }
      if (response2 && response2.data) {
        setContacts(response2?.data);
      }
      if (response3 && response3.data) {
        setSignatureObject(response3?.data);
      }
    } catch (error) {
      console.log(error.response?.data.message);
    }
  };

  const getAllocationReceipByOrderId = async (id) => {
    const apiUrl = `${URL}/allocation/receipt/${id}`;
    try {
      const response = await axios.get(apiUrl);
      if (response && response.data) {
        console.warn(response);
        setReceiptData(response?.data);
        setCurentOrderId(id);
      }
    } catch (error) {
      setReceiptData("{}");
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.detail === "Student not found"
      ) {
        toast.error("Student not found");
      }
      console.log(error);
    }
  };

  const receiptStyles = isPrintReady
    ? {
        backgroundColor: "white",
        width: "210mm",
        minHeight: "297mm",
        margin: "5px",
        padding: "5mm",
        boxShadow: "none",
        // border: "1px solid black",
      }
    : {
        // Default styles for viewing on the screen before downloading
        width: "100%", // Full width for better preview on screen
        minHeight: "120mm", // Smaller height for screen view
        margin: "10px auto", // Center it with less margin
        padding: "10px", // Less padding
        backgroundColor: "#f0f0f0", // Different background to distinguish from print-ready
        // boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Adding some shadow for aesthetics
        // border: "1px solid #ccc", // Lighter border for on-screen
        // borderRadius: 10,
      };

  const handleDownloadPdf = () => {
    setIsPrintReady(true); // Set to print ready before capturing
    setTimeout(() => {
      html2canvas(receiptRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        });
        pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
        pdf.save("receipt.pdf");
        setIsPrintReady(false); // Reset after download
      });
    }, 100); // Delay to ensure styles are applied
  };

  // const handleViewPdf = () => {
  //   const capture = receiptRef.current;
  //   html2canvas(capture).then((canvas) => {
  //     const imgData = canvas.toDataURL("img/png");
  //     const doc = new jsPDF("p", "mm", "a4");
  //     const componentWidth = doc.internal.pageSize.getWidth();
  //     const componentHeight = doc.internal.pageSize.getHeight();
  //     doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);

  //     // Get the PDF as data URI
  //     const pdfData = doc.output("datauristring");

  //     // Open a new tab and navigate to the PDF
  //     window.open(pdfData, "_blank");
  //   });
  // };

  const deleteAllocationRequest = async (id) => {
    try {
      const response = await axios.delete(
        `${URL}/allocation/allocation_request/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        await getRoomAllocationHistory();
        toast.success("Allocation request deleted successfully", {
          icon: "🟢",
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log("Error in handleChangeStatus:", error.message);
    }
  };

  const handleChangeStatus = async (data) => {
    try {
      let prevStatus = data.allotted;
      console.log(data);
      console.log("before", prevStatus);

      // Toggle status
      prevStatus = !prevStatus;

      const response = await axios.put(
        `${URL}/allocation/verify_allocation`,
        {},
        {
          params: {
            order_id: data.order_id,
            transaction_verification_status: prevStatus,
            allotted: prevStatus,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        await getRoomAllocationHistory();
        if (response.data.allotted === true) {
          toast.success("Allocation verified", {
            icon: "🟢",
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.error("Allocation unverified", {
            icon: "🔴",
            position: "top-right",
            autoClose: 3000,
          });
        }
      }
    } catch (error) {
      console.log("Error in handleChangeStatus:", error.message);
      toast.error("Error updating allocation status", {
        icon: "🔴",
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const getRoomAllocationHistory = async () => {
    const url = `${URL}/allocation/allocation_requests`;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(url, config);
      setRoomAllocationHistory(res?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getStudents = async () => {
    const url = `${URL}/student/?page=${1}`;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(url, config);
      setStudent(res?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getRoomAllocationHistory();
    getStudents();
    getAdminOrganazationDetails();
  }, [contactDetailsAsString]);

  useEffect(() => {
    const filterData = RoomAllocationHistory?.filter((room) => {
      if (!room) {
        return false;
      }

      const query = search.toLowerCase();

      if (!search) {
        return true;
      }

      const hostel = room.hostel_name ? room.hostel_name.toLowerCase() : "";
      const order = room.order_id ? room.order_id.toLowerCase() : "";
      const roomName = room.room_name ? room.room_name.toLowerCase() : "";

      const studentName =
        student
          .find((s) => s.student_id === room.student_id)
          ?.name.toLowerCase() || "";

      return (
        hostel.includes(query) ||
        order.includes(query) ||
        roomName.includes(query) ||
        studentName.includes(query)
      );
    });
    setFilterData(filterData);
  }, [search, RoomAllocationHistory, student]);

  useEffect(() => {
    const filterData = RoomAllocationHistory?.filter((room) => {
      if (status === "all") {
        return RoomAllocationHistory;
      }
      if (status === "pending") {
        return room?.allotted === null;
      }
      if (status === "verify") {
        return room?.allotted === true;
      }
      if (status === "reject") {
        return room?.allotted === false;
      }
    });
    setFilterData(filterData);
  }, [status, RoomAllocationHistory]);

  const sortData = [...filterData].sort((a, b) => {
    const dueDateA = new Date(a.allocation_request_at);
    const dueDateB = new Date(b.allocation_request_at);

    if (dueDateA < dueDateB) {
      return -1;
    } else if (dueDateA > dueDateB) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <Box bgcolor={"#EEEEFF"} minHeight={"100vh"}>
      <Box
        sx={{
          [theme.breakpoints.up("xs")]: {
            marginLeft: "0px",
            padding: "10px 0px",
          },
          [theme.breakpoints.up("md")]: {
            marginLeft: "265px",
            padding: "10px 10px",
          },
        }}
      >
        <p
          className="container mt-3"
          style={{ fontSize: "20px", fontWeight: "700", color: "#384D6C" }}
        >
          Room Allocation History
        </p>

        <div
          className="d-flex w-100 pb-4"
          style={{
            backgroundColor: "#EEEEFF",
            marginLeft: "0px",
            paddingTop: "10px",
          }}
        >
          <div className="w-100" style={{ position: "relative" }}>
            <input
              className="p-2 ms-1 ps-3 input-searchfield"
              style={{
                border: "2px solid black",
                borderRadius: "25px",
                color: "black",
                fontWeight: "bold",
              }}
              type="text"
              placeholder="Enter Order Id"
              onChange={(e) => setSearch(e.target.value)}
            />
            <span
              className="serchicon"
              style={{
                position: "absolute",
                top: "50%",
                right: "965px",
                transform: "translateY(-50%)",
                color: "#888",
              }}
            ></span>
          </div>
        </div>
        <div style={{ backgroundColor: "#EEEEFF" }}></div>
        {/* </div> */}

        <TableContainer
          component={Paper}
          style={{ color: "#384D6C", backgroundColor: "#EEEEFF" }}
        >
          <Table
            id="offers-table"
            sx={{ minWidth: 550 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ fontWeight: "bold" }}>
                <TableCell
                  style={{ color: "#384D6C", fontSize: "16px" }}
                  align="left"
                >
                  {" "}
                  &nbsp; &nbsp; No.
                </TableCell>
                <TableCell
                  style={{
                    color: "#384D6C",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  align="center"
                >
                  Name
                </TableCell>

                <TableCell
                  style={{ color: "#384D6C", fontSize: "16px" }}
                  align="center"
                >
                  Hostel
                </TableCell>
                <TableCell
                  style={{ color: "#384D6C", fontSize: "16px" }}
                  align="center"
                >
                  Room Rent
                </TableCell>

                <TableCell
                  style={{ color: "#384D6C", fontSize: "16px" }}
                  align="center"
                >
                  Order ID/UTR{" "}
                </TableCell>
                <TableCell
                  style={{ color: "#384D6C", fontSize: "16px" }}
                  align="center"
                >
                  Allocation Req at
                </TableCell>
                <TableCell
                  style={{ color: "#384D6C", fontSize: "16px" }}
                  align="center"
                >
                  <FormControl>
                    <NativeSelect
                      onChange={(e) => setStatus(e.target.value)}
                      sx={{
                        color: "#384D6C",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      <option
                        value={"all"}
                        style={{ color: "#384D6C", fontSize: "16px" }}
                        align="center"
                      >
                        All
                      </option>
                      <option
                        value={"pending"}
                        style={{ color: "#384D6C", fontSize: "16px" }}
                        align="center"
                      >
                        Pending
                      </option>
                      <option
                        value={"verify"}
                        style={{ color: "#384D6C", fontSize: "16px" }}
                        align="center"
                      >
                        Verified
                      </option>
                      <option
                        value={"reject"}
                        style={{ color: "#384D6C", fontSize: "16px" }}
                        align="center"
                      >
                        Rejected
                      </option>
                    </NativeSelect>
                  </FormControl>
                </TableCell>
                <TableCell
                  style={{ color: "#384D6C", fontSize: "16px" }}
                  align="center"
                >
                  Print
                </TableCell>

                <TableCell
                  style={{ color: "#384D6C", fontSize: "16px" }}
                  align="center"
                >
                  Change Status
                </TableCell>
                <TableCell
                  style={{ color: "#384D6C", fontSize: "16px" }}
                  align="center"
                >
                  Delete Request
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortData?.map((obj, i) => {
                const {
                  allocation_request_at,
                  allotted,
                  hostel_name,
                  order_id,
                  payment_due_on,
                  room_id,
                  room_name,
                  room_rent,
                  student_id,
                } = obj;
                return (
                  <>
                    <TableRow key={room_id}>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell align="center">
                        {student?.map((obj) => {
                          if (obj?.student_id === student_id) {
                            if (obj?.name) {
                              return obj.name;
                            }
                          }
                        })}
                      </TableCell>
                      <TableCell align="center">
                        {hostel_name}
                        <br />
                        <span style={{ color: "gray", fontSize: "12px" }}>
                          {room_name || "Not available"}
                        </span>
                      </TableCell>
                      <TableCell align="center">{room_rent}</TableCell>
                      <TableCell align="center">{order_id}</TableCell>
                      <TableCell align="center">
                        {new Date(allocation_request_at).toLocaleDateString(
                          "en-IN"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {allotted === false && (
                          <Button
                            variant="contained"
                            style={{
                              height: "34px",
                              width: "140px",
                              fontSize: "16px",
                              borderRadius: "25px",
                              backgroundColor: "red",
                            }}
                          >
                            <CancelIcon /> <ListItemText>Reject</ListItemText>
                          </Button>
                        )}
                        {allotted === null && (
                          <Button
                            variant="contained"
                            style={{
                              height: "34px",
                              width: "140px",
                              fontSize: "16px",
                              borderRadius: "25px",
                              backgroundColor: "#F7B946",
                            }}
                          >
                            <PauseIcon /> <ListItemText> Pending</ListItemText>
                          </Button>
                        )}
                        {allotted === true && (
                          <Button
                            variant="contained"
                            style={{
                              height: "34px",
                              width: "140px",
                              fontSize: "16px",
                              borderRadius: "25px",
                              background: "#CBFDB3",
                              color: "green",
                              fontWeight: "bold",
                            }}
                          >
                            <CloudDoneIcon sx={{ fill: "green" }} />{" "}
                            <ListItemText> Verified</ListItemText>
                          </Button>
                        )}
                      </TableCell>
                      <TableCell
                        style={{ color: "#384D6C", fontSize: "16px" }}
                        align="center"
                      >
                        <PrintIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleOpen();
                            getAllocationReceipByOrderId(obj.order_id);
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <AutorenewIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleChangeStatus(obj);
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          style={{ cursor: "pointer", color: "red" }}
                          onClick={() => {
                            deleteAllocationRequest(obj.order_id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={""}
            rowsPerPage={""}
            page={""}
            onPageChange={""}
            onRowsPerPageChange={""}
          />
        </TableContainer>
        <Toaster />
      </Box>
      <Modal onClose={handleClose} open={open}>
        <Box
          sx={{
            [theme.breakpoints.up("xs")]: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#f0f0f0",
              boxShadow: 24,
              padding: "5px",
              fontFamily: "Arial, sans-serif",
              borderRadius: "8px",
              width: "95%",
            },
            [theme.breakpoints.up("md")]: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#f0f0f0",
              boxShadow: 24,
              padding: "20px",
              fontFamily: "Arial, sans-serif",
              borderRadius: "8px",
              width: "60%",
            },
          }}
        >
          {receiptData && receiptData["name of student"] !== "" ? (
            <Box
              sx={{
                [theme.breakpoints.up("xs")]: {
                  marginY: "10px",
                  marginX: "20px",
                },
                [theme.breakpoints.up("md")]: {},
              }}
            >
              <Box className="d-flex justify-content-between ">
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: { xs: "16px", md: "22px" },
                  }}
                >
                  Print Receipt
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: { xs: "16px", md: "22px" },
                  }}
                >
                  <CloseIcon
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={handleClose}
                  />
                </Typography>
              </Box>

              <Box sx={{ height: "500px", overflowY: "auto" }}>
                <div className="" ref={receiptRef} style={receiptStyles}>
                  <main
                    className=" rounded p-4"
                    style={{ border: "2px solid black" }}
                  >
                    <section className="frame-group ">
                      <div className="frame-container d-flex justify-content-between">
                        <div className="indr-hostels-parent">
                          <h1 className="indr-hostels fw-bolder">
                            {organization?.name
                              ? organization?.name
                              : "NA Hostel"}{" "}
                          </h1>
                          <div className="add-near-mahesh-container">
                            <p className="add-near-mahesh m-0 fw">
                              <span className="fw-bold">Address : </span>
                              {organization?.address
                                ? organization?.address
                                : "NA"}
                            </p>
                            <p className="jaipur-302018 m-0">Jaipur-302018</p>

                            <p className="m-0 text-warning-emphasis">
                              <span className="fw-bold">Website : </span>
                              {organization?.website
                                ? organization?.website
                                : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="fr">
                          <div className="pa">
                            {contacts[0]?.detail.map((item, i) => {
                              return (
                                <p className="m-0 mb-1" key={i}>
                                  {item}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="main-layout-footer">
                        <div className="frame-div">
                          <Divider sx={{ border: "2px solid gray", my: 2 }} />
                          <div className="frame-wrapper1 text-center">
                            <button
                              onClick={handleDownloadPdf}
                              className="rectangle-container text-center  mb-2"
                              style={{
                                padding: "10px 20px",
                                borderRadius: "6px",
                                backgroundColor: "#384D6C",
                                color: "white",
                                fontWeight: "bold",
                                border: "none",
                              }}
                            >
                              ROOM RENT RECEIPT
                            </button>
                          </div>
                          <div className="frame-parent1">
                            <div className="main-layout-parent ">
                              <div className="main-layout">
                                <div className="receipt-no-wrapper d-flex justify-content-between mb-3">
                                  <div className="receipt-no fw-bold">
                                    Receipt No. :{" "}
                                    <input
                                      placeholder="Enter Receipt No"
                                      type="text"
                                      style={{
                                        backgroundColor: "transparent",
                                        borderTop: "none",
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderBottom: "1px solid gray",
                                        outline: "none",
                                        width: "130px",
                                        fontWeight: "bold",
                                      }}
                                    />
                                  </div>
                                  <div className="date- ">
                                    <span className="fw-bold">Date :</span>{" "}
                                    {receiptData?.date
                                      ? new Date(
                                          receiptData.date
                                        ).toLocaleDateString("en-IN")
                                      : "NA"}
                                  </div>
                                </div>

                                <div className="receipt-no-wrapper d-flex justify-content-between  mb-3">
                                  <div className="receipt-no ">
                                    <span className="fw-bold">
                                      {" "}
                                      Name of Student :{" "}
                                    </span>{" "}
                                    {receiptData?.name_of_student
                                      ? receiptData?.name_of_student
                                      : "NA "}
                                  </div>
                                  <div className="date- ">
                                    <span className="fw-bold">Room No :</span>{" "}
                                    {receiptData?.room_no
                                      ? receiptData?.room_no
                                      : "NA "}
                                  </div>
                                </div>

                                <div className="receipt-no-wrapper d-flex justify-content-between  mb-3">
                                  <div className="receipt-no ">
                                    <span className="fw-bold">
                                      {" "}
                                      Father’s Name :{" "}
                                    </span>{" "}
                                    {receiptData.father_name
                                      ? receiptData.father_name
                                      : "NA"}
                                  </div>
                                  <div className="date- ">
                                    <span className="fw-bold">
                                      {" "}
                                      Hostel Name :
                                    </span>{" "}
                                    {receiptData?.hostel_name
                                      ? receiptData?.hostel_name
                                      : "NA "}
                                  </div>
                                </div>

                                <div className="mobile-no-container mb-3">
                                  <span className="mobile-no ">
                                    <span className=" fw-bold">
                                      {" "}
                                      Mobile No :
                                    </span>{" "}
                                    {receiptData?.contact_no
                                      ? receiptData?.contact_no
                                      : "NA "}{" "}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="date-parent    ">
                                <div className="room-no-container mb-3">
                                  <span className="room-no">
                                    <span className="fw-bold">
                                      Hostel Name :
                                    </span>{" "}
                                    {receiptData?.hostel_name
                                      ? receiptData?.hostel_name
                                      : "NA "}
                                  </span>
                                </div>
                              </div> */}
                            </div>
                            <div className="address-container mb-3">
                              <p className="address-">
                                <span className="fw-bold">Address :</span>{" "}
                                {receiptData?.permanent_address
                                  ? receiptData?.permanent_address
                                  : "NA "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Divider sx={{ border: "2px solid gray", my: 2 }} />

                      <div className="frame-wrapper2">
                        <div className="mode-of-payment-cash-chequ-parent">
                          {/* <div className="mode-of-payment fw-bold">
                            Mode of Payment : Cash / Cheque / UPI / Netbanking /
                            DD
                          </div> */}
                          <div className="utr-no-order-id-parent">
                            <div className="utr-no-container mb-3">
                              <span className="utr-no fw-bold">
                                UTR No. / Order ID :
                              </span>
                              <span className="span4"> {curentOrderId}</span>
                            </div>
                            <div className="room-rent mb-3">
                              <span className="fw-bold"> Room Rent: ₹ </span>{" "}
                              {receiptData?.rupees ? receiptData.rupees : "NA "}{" "}
                              /-
                            </div>
                            {/* <div className="room-rent-in-container mb-3">
                              <span className="room-rent-in fw-bold">
                                Room Rent( in words ) :{" "}
                              </span>
                              <span className="span5">_______________</span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="website-link-wrapper mt-5 d-flex justify-content-end">
                        <div className="website-link">
                          <div className="for-indr-container mb-3">
                            <span>For</span>
                            <span className="indr-hostels1">
                              {" "}
                              :{" "}
                              {organization?.name
                                ? organization?.name
                                : "NA Hostel"}{" "}
                            </span>
                          </div>
                          <div className="authorised-signature-wrapper mb-3">
                            <div
                              className="authorised-signature "
                              style={{ color: "#b3aead" }}
                            >
                              Authorised Signature
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </main>
                </div>
              </Box>
              <Button
                onClick={handleDownloadPdf}
                sx={{ ml: 2 }}
                variant="outlined"
              >
                Download PDF
              </Button>
            </Box>
          ) : (
            <Typography variant="body1">NO student found</Typography>
          )}
        </Box>
      </Modal>
      ;
    </Box>
  );
}

export default RoomAllocationHistory;
