import React, { useState, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { getUserFromLocalStorage } from "../../service/localstorage";
import { getApiUrl } from "../../utils/getApiUrl";

function UploadPayment({ onQrSuccess, onQrFailure }) {
  const URL = getApiUrl();
  const apiKey = process.env.REACT_APP_API_KEY;
  const [selectedFile, setSelectedFile] = useState(null);
  const inputRef = useRef();
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");
  const [upiId, setUpiId] = useState("");

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };
  const onChooseFile = () => {
    inputRef.current.click();
  };

  const clearFileInput = () => {
    inputRef.current.value = "";
    setSelectedFile(null);
    setProgress(0);
    setUploadStatus("select");
  };

  const handleUpload = async () => {
    if (!upiId) {
      toast.error("UPI ID is required");
      return;
    }
    if (uploadStatus === "done") {
      clearFileInput();
      return;
    }
  
    try {
      const user = getUserFromLocalStorage();
      const token = user?.token;
      setUploadStatus("uploading");
  
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("upi_id", upiId);
  
      const response = await axios.post(
        `${URL}/admin/qrcode`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );
  
      if (response) {
        onQrSuccess(response);
        toast.success("Image uploaded successfully ✅");
        clearFileInput();
      }
  
      setUploadStatus("done");
    } catch (error) {
      onQrFailure(error);
      // toast.error("Failed to upload image ❌");
      setUploadStatus("select");
    }
  };
  

  return (
    <>
      <div className="mb-3">
        <input
          type="text"
          className="form-control py-3"
          placeholder="please enter upi id"
          value={upiId}
          onChange={(e) => setUpiId(e.target.value)}
        />
      </div>
      <div className="d-flex justify-content-center  flex-column">
        <input
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />

        {!selectedFile && (
          <button className="file-btn w-100" onClick={onChooseFile}>
            <span className="material-symbols-outlined">
              <CloudUploadIcon />
            </span>{" "}
          </button>
        )}

        {selectedFile && (
          <>
            <div className="file-card">
              <span className="material-symbols-outlined icon">
                <DescriptionIcon />
              </span>

              <div className="file-info">
                <div style={{ flex: 1 }}>
                  <h6>{selectedFile?.name}</h6>

                  <div className="progress-bg">
                    <div
                      className="progress"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>

                {uploadStatus === "select" ? (
                  <button onClick={clearFileInput}>
                    <span className="material-symbols-outlined close-icon">
                      <CloseIcon />
                    </span>
                  </button>
                ) : (
                  <div className="check-circle">
                    {uploadStatus === "uploading" ? (
                      `${progress}%`
                    ) : uploadStatus === "done" ? (
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: "20px" }}
                      >
                        <CheckIcon />
                      </span>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <button className="upload-btn" onClick={handleUpload}>
              {uploadStatus === "select" || uploadStatus === "uploading"
                ? "Upload"
                : "Done"}
            </button>
          </>
        )}
      </div>
    </>
  );
}

export default UploadPayment;
