export const getApiUrl = () => {
  const hostname = window.location.hostname;

  if (hostname.includes("localhost")) {
    return "https://test-admin-api.affx.cloud";
  }

  const parts = hostname.split('.');
  let apiHostname;

  if (parts.length === 3) {
    const [subdomain, domain, tld] = parts;

    if (subdomain === "admin") {
      apiHostname = `${domain}-admin-api.affx.cloud`;
    } else if (subdomain.endsWith("-admin-panel")) {
      const baseSubdomain = subdomain.replace("-admin-panel", "");
      apiHostname = `${baseSubdomain}-admin-api.affx.cloud`;
    } else {
      apiHostname = `${domain}-admin-api.affx.cloud`;
    }
  }

  return `https://${apiHostname}`;
};
