import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useTheme,
  Paper,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import Autocomplete from "@mui/material/Autocomplete";
import { getUserFromLocalStorage } from "../service/localstorage";
import { getApiUrl } from "../utils/getApiUrl";
const URL = getApiUrl();
function DirectAllocation() {

  const theme = useTheme();
  const [selectedOccupantId, setSelectedOccupantId] = useState("");
  const [allOccupantData, setAllOccupantData] = useState([]);
  const [allSiteAndNameData, setAllSiteAndNameData] = useState([]);
  const [selectedHostel, setSelectedHostel] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState("");
  const [orderId, setOrderId] = useState("");
  const [currentSite, setCurrentSite] = useState("");
  const [loading, setLoading] = useState(false);
  const [customRoomRent, setCustomRoomRent] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [sendDueDateToBackend, setSendDueDateToBackend] = useState("");

  const user = getUserFromLocalStorage();
  const token = user?.token;
  const tokenType = user?.tokenType || "Bearer";

  useEffect(() => {
    const today = new Date();
    const defaultDueDate = new Date();
    defaultDueDate.setDate(today.getDate() + 30);

    const formattedDueDateToShowOnFrontend = defaultDueDate
      .toISOString()
      .split("T")[0];
    const formattedDueDateToSendOnBackend = defaultDueDate.toISOString();
    setDueDate(formattedDueDateToShowOnFrontend);
    setSendDueDateToBackend(formattedDueDateToSendOnBackend);
  }, []);

  const handleSiteChange = (event) => {
    const siteName = event.target.value;
    setCurrentSite(siteName);
    const site = allSiteAndNameData.find(
      (site) => site.hostel_name === siteName
    );
    setSelectedHostel(site);
    setSelectedSpace("");
  };

  const handleSpaceChange = (event) => {
    const spaceName = event.target.value;
    setSelectedSpace(spaceName);

    // Find the selected room and its rent from the selected site
    const selectedRoom = selectedHostel.rooms.find(
      (room) => room.room_name === spaceName
    );
    if (selectedRoom) {
      setCustomRoomRent(selectedRoom.room_rent.toString());
    } else {
      setCustomRoomRent("");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!selectedOccupantId) {
      toast.error("Please select a student.");
      setLoading(false);
      return;
    }

    if (!currentSite) {
      toast.error("Please select a Site.");
      setLoading(false);
      return;
    }
    if (!selectedSpace) {
      toast.error("Please select a room.");
      setLoading(false);
      return;
    }

    if (!customRoomRent) {
      toast.error("Please select a valid rent.");
      setLoading(false);
      return;
    }

    if (!orderId) {
      toast.error("Please Enter Order Id.");
      setLoading(false);
      return;
    }
    if (!dueDate) {
      toast.error("Please Enter Due Date.");
      setLoading(false);
      return;
    }

    const apiUrl = `${URL}/allocation/direct_allocate_room?student_id=${selectedOccupantId}&hostel_name=${currentSite}&room_name=${selectedSpace}&order_id=${orderId}&room_rent=${customRoomRent}&payment_due_on=${sendDueDateToBackend}`;
    try {
      const response = await axios.post(apiUrl, null, {
        headers: {
          Authorization: `${tokenType} ${token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      toast.success("Form submitted successfully");
      setSelectedOccupantId("");
      setSelectedSpace("");
      setCurrentSite("");
      setOrderId("");
      setCustomRoomRent(""); 
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.detail === "The room is already occupied"
      ) {
        toast.error("The room is already fully occupied");
      } else if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message ===
          "An allocation request with this order_id already exists."
      ) {
        toast.error("An allocation request with this order id already exists.");
      } else {
        console.error("Error:", error);
      }
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }
  };

  const getAllSiteAndSpaceData = async () => {
    const apiUrl = `${URL}/room/hostels_and_rooms`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `${tokenType} ${token}`,
        },
      });
      if (response) {
        toast.success("Hostel data fetched successfully!", {
          icon: "🟢",
          position: "top-right",
          autoClose: 3000,
        });
        setAllSiteAndNameData(response.data);
      }
    } catch (e) {
      console.log(`Error: ${e}`);
      toast.error("Failed to fetch site data!", {
        icon: "❌",
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const getOccupantBasicInfo = async () => {
    const apiUrl = `${URL}/student/basic-info`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `${tokenType} ${token}`,
        },
      });
      if (response) {
        toast.success("Student data fetched successfully!", {
          icon: "🟢",
          position: "top-right",
          autoClose: 3000,
        });
        setAllOccupantData(response.data);
      }
    } catch (e) {
      console.log(`Error: ${e}`);
      toast.error("Failed to fetch student data!", {
        icon: "❌",
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getOccupantBasicInfo();
    getAllSiteAndSpaceData();
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: "#EEEEFF",
          width: "100%",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.up("xs")]: {
              marginLeft: "0px",
              padding: "10px 0px",
            },
            [theme.breakpoints.up("md")]: {
              marginLeft: "265px",
              padding: "10px 10px",
            },
          }}
        >
          <Box className="py-4 px-2 ">
            <span
              className="container"
              style={{ fontSize: "20px", fontWeight: "700", color: "#384D6C" }}
            >
              Direct Space Allocation :
            </span>
          </Box>

          <Paper
            sx={{ width: { xs: "300px", md: "600px" }, marginX: "auto", mt: 4 }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-cener"
              >
                <Autocomplete
                  fullWidth
                  sx={{ margin: 2 }}
                  id="search-student"
                  options={allOccupantData}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setSelectedOccupantId(newValue?.student_id || "");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Student" />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <FormControl fullWidth sx={{ margin: 2 }}>
                  <InputLabel id="dropdown-label-2">Select a hostel</InputLabel>
                  <Select
                    labelId="dropdown-label-2"
                    id="dropdown-2"
                    onChange={handleSiteChange}
                  >
                    <MenuItem value="">Select Site</MenuItem>
                    {allSiteAndNameData.map((site, index) => (
                      <MenuItem key={index} value={site.hostel_name}>
                        {site.hostel_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <FormControl fullWidth sx={{ margin: 2 }}>
                  <InputLabel id="dropdown-label-3">Select a room</InputLabel>
                  <Select
                    labelId="dropdown-label-3"
                    id="dropdown-3"
                    value={selectedSpace}
                    onChange={handleSpaceChange}
                  >
                    <MenuItem value="">Select a room</MenuItem>
                    {selectedHostel?.rooms.map((room, index) => (
                      <MenuItem key={index} value={room.room_name}>
                        {room.room_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <TextField
                  sx={{ margin: 2 }}
                  label="Enter rent"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={customRoomRent}
                  onChange={(e) => setCustomRoomRent(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <TextField
                  sx={{ margin: 2 }}
                  label="Payment Due on"
                  variant="outlined"
                  type="date"
                  fullWidth
                  id="dueDate"
                  name="dueDate"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <TextField
                  sx={{ margin: 2 }}
                  label="Order Id"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <Button
                  sx={{ width: "300px", margin: 2 }}
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Submitting" : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default DirectAllocation;
