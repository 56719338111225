import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  Divider,
  InputLabel,
  Input,
} from "@mui/material";
import React from "react";

function HostelSlip() {
  const theme = useTheme();
  return (
    <>
      <Box bgcolor={"#EEEEFF"} minHeight={"100vh"}>
        <Box
          sx={{
            [theme.breakpoints.up("xs")]: {
              marginLeft: "0px",
              padding: "10px 0px",
            },
            [theme.breakpoints.up("md")]: {
              marginLeft: "265px",
              padding: "10px 10px",
            },
          }}
        >
          <p
            className="container mt-3"
            style={{ fontSize: "20px", fontWeight: "700", color: "#384D6C" }}
          >
            Hostel Sleep
          </p>

          <Paper sx={{ p: 2 }}>
            <Grid container spacing={{ xs: 0.2, md: 0 }}>
              <Grid item xs={12} md={4}>
                <Typography
                  m={1}
                  variant="h3"
                  sx={{
                    fontFamily: "san",
                    fontWeight: "bold",
                    textAlign: "center",
                    [theme.breakpoints.down("md")]: { fontSize: "2rem" },
                  }}
                >
                  VIDHYA HOSTEL
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box m={1} sx={{ width: "85%" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: "san", fontWeight: "bold" }}
                  >
                    Add :{" "}
                    <span className="text-secondary">
                      Shree Gopal Nagar, Near Mahesh Nagar Police Station Jaipur
                      - 302018
                    </span>
                  </Typography>
                  <Typography
                    mt={1}
                    variant="h6"
                    sx={{ fontFamily: "san", fontWeight: "bold" }}
                  >
                    Website :{" "}
                    <span className="text-secondary">
                      {" "}
                      www.vidhyahostel.com
                    </span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider
              sx={{
                border: "3px solid black ",
                fontWeight: "bold",
                backgroundColor: "black",
              }}
            />

            <Box>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div className="row gtr-uniform m-3">
                    <InputLabel htmlFor="no" className="mb-2 fw-bold">
                      No
                    </InputLabel>
                    <Input
                      id="no"
                      placeholder="Enter Slip No"
                      name="no"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="row gtr-uniform m-3">
                    <InputLabel htmlFor="date" className="mb-2 fw-bold">
                      Date
                    </InputLabel>
                    <Input
                      id="date"
                      placeholder="Enter Slip No"
                      name="no"
                      className="form-control"
                      type="date"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <div className="row gtr-uniform m-3">
                    <InputLabel htmlFor="student" className="mb-2 fw-bold">
                      Name of Student
                    </InputLabel>
                    <Input
                      id="student"
                      placeholder="Enter your full name"
                      name="no"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="row gtr-uniform m-3">
                    <InputLabel htmlFor="father" className="mb-2 fw-bold">
                      Name of Father
                    </InputLabel>
                    <Input
                      id="father"
                      placeholder="Enter your father name"
                      name="no"
                      className="form-control"
                    />
                  </div>
                </Grid>{" "}
                <Grid item xs={12} md={4}>
                  <div className="row gtr-uniform m-3">
                    <InputLabel htmlFor="address" className="mb-2 fw-bold">
                      Address
                    </InputLabel>
                    <Input
                      id="address"
                      placeholder="Enter your address"
                      name="no"
                      className="form-control"
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <div className="row gtr-uniform m-3">
                    <InputLabel
                      htmlFor="ruppes-in-word"
                      className="mb-2 fw-bold"
                    >
                      Rupees in Word
                    </InputLabel>
                    <Input
                      id="ruppes-in-word"
                      placeholder="Enter rupees in word"
                      name="no"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="row gtr-uniform m-3">
                    <InputLabel htmlFor="tr-type" className="mb-2 fw-bold">
                      By Cash/Cheque/Net Banking/Draft No.
                    </InputLabel>
                    <Input
                      id="tr-type"
                      placeholder="Enter transaction type"
                      name="no"
                      className="form-control"
                    />
                  </div>
                </Grid>{" "}
                <Grid item xs={12} md={4}>
                  <div className="row gtr-uniform m-3">
                    <InputLabel htmlFor="tr-date" className="mb-2 fw-bold">
                      Transaction Date
                    </InputLabel>
                    <Input
                      id="tr-date"
                      name="no"
                      className="form-control"
                      type="date"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="row gtr-uniform m-3">
                    <InputLabel htmlFor="ac-no" className="mb-2 fw-bold">
                      In Account of
                    </InputLabel>
                    <Input
                      id="ac-no"
                      name="no"
                      className="form-control"
                      type="text"
                      placeholder="Enter account number"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="row gtr-uniform m-3">
                    <InputLabel htmlFor="tr-am" className="mb-2 fw-bold">
                     RS :
                    </InputLabel>
                    <Input
                      id="tr-am"
                      placeholder="Enter amount"
                      name="no"
                      className="form-control"
                      type="number"
                    />
                  </div>
                </Grid>{" "}
                <Grid item xs={12} md={6}>
                  <div className="row gtr-uniform m-3">
                    <InputLabel htmlFor="tr-h" className="mb-2 fw-bold">
                   For : VIDHYA HOSTEL
                    </InputLabel>
                    <Input
                      id="tr-h"
                      name="no"
                      className="form-control"
                      type="file"

                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default HostelSlip;
