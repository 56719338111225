import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  useTheme,
  Paper,
  Button,
  Typography,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import Person2Icon from "@mui/icons-material/Person2";
import BadgeIcon from "@mui/icons-material/Badge";
import WcIcon from "@mui/icons-material/Wc";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import toast from "react-hot-toast";
import axios from "axios";
import { getApiUrl } from "../utils/getApiUrl";
import { getUserFromLocalStorage } from "../service/localstorage";

const StudentEditProfile = () => {
  const URL = getApiUrl();
  const apikey = process.env.REACT_APP_API_KEY;
  const [additionalInformation, setAdditionalInformation] = useState({
    name: "",
    bio: "",
    dob: "",
    gender: "",
    contact_no: "",
    email: "",
    institute_name: "",
    fathers_name: "",
    fathers_occupation: "",
    mother_name: "",
    mothers_occupation: "",
    local_guardian_name: "",
    local_guardian_occupation: "",
    relation_local_guardian: "",
    permanent_address: "",
    city: "",
    state: "",
    address_building: "",
    password: "string",
  });
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const [previewImageProfile, setPreviewImageProfile] = useState(null);
  const [profileImg, setProfileImg] = useState("");
  const profileRef = useRef();

  const [previewImageAdharFront, setPreviewImageAdharFront] = useState(null);
  const [adharFrontImg, setAdharFrontImg] = useState("");
  const adharFrontRef = useRef();

  const [previewImageAdharBack, setPreviewImageAdharBack] = useState(null);
  const [adharBackImg, setAdharBackImg] = useState("");
  const adharBackRef = useRef();

  const [getProfileImage, setGetProfileImage] = useState("");
  const [getAdharFrontImage, setGetAdharFrontImage] = useState("");
  const [getAdharBackImage, setGetAdharBackImage] = useState("");
  const { id } = useParams();

  const [shoModel, setShowModel] = useState(false);
  const genderOptions = ["Male", "Female", "Other"];

  const getOccupantData = async () => {
    const apiUrl = `${URL}/student/${id}`;
    try {
      const response = await axios.get(apiUrl);
      if (response && response.data) {
        setAdditionalInformation(response.data);
        const { profile_pic, aadharfront_pic, aadharback_pic } = response.data;
        setGetProfileImage(profile_pic);
        setGetAdharFrontImage(aadharfront_pic);
        setGetAdharBackImage(aadharback_pic);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const clearProfileImage = () => {
    setPreviewImageProfile(null);
    setProfileImg("");
    if (profileRef.current) {
      profileRef.current.value = null;
    }
  };

  const clearAdharFrontImage = () => {
    setPreviewImageAdharFront(null);
    setAdharFrontImg("");
    if (adharFrontRef.current) {
      adharFrontRef.current.value = null;
    }
  };

  const clearAdharBackImage = () => {
    setPreviewImageAdharBack(null);
    setAdharBackImg("");
    if (adharBackRef.current) {
      adharBackRef.current.value = null;
    }
  };

  const handleClickForProfile = () => {
    if (profileRef.current) {
      profileRef.current.click();
    }
  };

  const handleClickForAdharFront = () => {
    if (adharFrontRef.current) {
      adharFrontRef.current.click();
    }
  };

  const handleClickForAdharBack = () => {
    if (adharBackRef.current) {
      adharBackRef.current.click();
    }
  };

  function profileFileHandler(e) {
    const file = e.target.files[0];
    if (!file) return;
    setProfileImg(file);
    const reader = new FileReader();
    reader.onload = function (event) {
      setPreviewImageProfile(event.target.result);
    };
    reader.readAsDataURL(file);
  }
  function adharFrontFileHandler(e) {
    const file = e.target.files[0];
    if (!file) return;
    setAdharFrontImg(file);
    const reader = new FileReader();
    reader.onload = function (event) {
      setPreviewImageAdharFront(event.target.result);
    };
    reader.readAsDataURL(file);
  }

  function adharBackFileHandler(e) {
    const file = e.target.files[0];
    if (!file) return;
    setAdharBackImg(file);
    const reader = new FileReader();
    reader.onload = function (event) {
      setPreviewImageAdharBack(event.target.result);
    };
    reader.readAsDataURL(file);
  }

  const uploadImg = async () => {
    const user = getUserFromLocalStorage();
    const token = user?.token;
    const tokenType = user?.tokenType || "Bearer";

    if (!profileImg) {
      toast.error("Please select a profile image!", 3000);
      setLoading(false);
      return;
    }
    if (!adharFrontImg) {
      setLoading(false);
      toast.error("Please select an Aadhar front image!", 3000);
      return;
    }
    if (!adharBackImg) {
      toast.error("Please select an Aadhar back image!", 3000);
      setLoading(false);
      return;
    }
    const apiUrl1 = `${URL}/student/update_profile_image?student_id=${id}`;
    const apiUrl2 = `${URL}/student/update_aadharfront_image?student_id=${id}`;
    const apiUrl3 = `${URL}/student/update_aadharback_image?student_id=${id}`;
    const formData1 = new FormData();
    formData1.append("file", profileImg);
    const formData2 = new FormData();
    formData2.append("file", adharFrontImg);
    const formData3 = new FormData();
    formData3.append("file", adharBackImg);
    try {
      setLoading(true);
      const response1 = await axios.post(apiUrl1, formData1, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenType} ${token}`,
        },
      });
      if (response1.status === 200) {
        toast.success("Profile image uploaded successfully!", 3000);
      } else {
        toast.error("Failed to upload profile image. Please try again!", 3000);
        setLoading(false);
      }
      const response2 = await axios.post(apiUrl2, formData2, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenType} ${token}`,
        },
      });
      if (response2.status === 200) {
        toast.success("Aadhar front image uploaded successfully!", 3000);
      } else {
        toast.error(
          "Failed to upload Aadhar front image. Please try again!",
          3000
        );
        setLoading(false);
      }
      const response3 = await axios.post(apiUrl3, formData3, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenType} ${token}`,
        },
      });
      if (response3.status === 200) {
        toast.success("Aadhar back image uploaded successfully!", 3000);
      } else {
        toast.error(
          "Failed to upload Aadhar back image. Please try again!",
          3000
        );
        setLoading(false);
      }
      clearProfileImage();
      clearAdharFrontImage();
      clearAdharBackImage();
      if (response1 && response2 && response3) {
        setShowModel(false);
        window.location.reload();
      }
    } catch (error) {
      console.log("Error:", error.message);
      setLoading(false);
      toast.error(
        "An error occurred while uploading images. Please try again later!",
        3000
      );
    } finally {
      setLoading(false);
    }
  };

  const Handelchange = (event) => {
    const { name, value } = event.target;
    setAdditionalInformation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitHandler = async () => {
    const user = getUserFromLocalStorage();
    const token = user?.token;
    const apiUrl = `${URL}/student/${id}`;
    const { name, email } = additionalInformation;

    setLoading(true);

    if (!name.trim() || !email.trim()) {
      toast.error("Name and email are required fields while updating!", {
        icon: "🔴",
        position: "top-right",
        autoClose: 3000,
      });
      setLoading(false);
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error("Please enter a valid email address!", {
        icon: "🔴",
        position: "top-right",
        autoClose: 3000,
      });
      setLoading(false);
      return;
    }

    try {
      const {
        aadharback_pic,
        aadharfront_pic,
        // address_building,
        // bio,
        // city,
        // contact_no,
        // dob,
        // email,
        // fathers_name,
        // fathers_occupation,
        // gender,
        // institute_name,
        // local_guardian_name,
        // local_guardian_occupation,
        // mother_name,
        // mothers_occupation,
        // name,
        // permanent_address,
        profile_pic,
        // relation_local_guardian,
        // state,
        password,
        created_at,
        verified,
        student_id,
        level,
        _id,
        ...data
      } = additionalInformation;
      console.log("=-->", data);

      const response = await axios.put(apiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response) {
        toast.success("Profile updated  successful!", {
          icon: "🟢",
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error.message);
      toast.error(" First update the filed which you want.", {
        icon: "🔴",
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
      getOccupantData();
    }
  };
  useEffect(() => {
    getOccupantData();
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: "#EEEEFF",
          width: "100%",
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.up("xs")]: {
              marginLeft: "0px",
              padding: "10px 0px",
            },
            [theme.breakpoints.up("md")]: {
              marginLeft: "265px",
              padding: "10px 10px",
            },
          }}
        >
          <Box className="py-4 px-2 ">
            <span
              className="container"
              style={{ fontSize: "20px", fontWeight: "700", color: "#384D6C" }}
            >
              Update Student Profile :
            </span>
          </Box>
          <Grid container>
            <Grid
              md={12}
              xs={12}
              className="d-flex justify-content-center align-items-center mt-1"
            >
              <Box style={{ padding: "10px" }}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="name"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Full Name :
                    </InputLabel>
                    <Input
                      id="name"
                      placeholder="Enter your full name"
                      name="name"
                      onChange={Handelchange}
                      value={additionalInformation.name}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <Person2Icon />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="bio"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Bio :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.bio}
                      id="bio"
                      name="bio"
                      placeholder="Enter your bio"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <BadgeIcon sx={{ margin: "0px" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="gender"
                      sx={{ fontWeight: "700", color: "#384D6C" }}
                    >
                      Gender :
                    </InputLabel>
                    <Select
                      onChange={Handelchange}
                      value={additionalInformation.gender}
                      name="gender"
                      id="gender"
                      placeholder="Select your gender"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <WcIcon sx={{ margin: "0px" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        // padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    >
                      {genderOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="phone"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Contact Number :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.contact_no}
                      id="phone"
                      placeholder="Enter contact number"
                      name="contact_no"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <PhoneEnabledIcon sx={{ margin: "0px" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="email"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Email Address :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.email}
                      id="email"
                      name="email"
                      placeholder="Enter your email address"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <EmailIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="date-of-joinning"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Date of Birth :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.dob}
                      id="date-of-joinning"
                      name="dob"
                      placeholder="Enter date of joining"
                      type="date"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                        display: "flex",
                        margin: "0 auto",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box
            container
            sx={{
              border: "1px solid #D0D0D0",
              width: "90%",
              margin: "25px auto",
            }}
          ></Box>
          <Box
            className="additional-info-containe"
            sx={{
              [theme.breakpoints.up("xs")]: {
                width: "100%",
                padding: "0 0px",
              },
              [theme.breakpoints.up("md")]: {
                width: "90%",
                padding: "0 30px",
                margin: "20px auto",
              },
            }}
          >
            <Box className="d-flex justify-content-between">
              <Box
                className=""
                sx={{
                  fontWeight: "700",
                  color: "#384D6C",
                  fontSize: "20px",
                  [theme.breakpoints.up("xs")]: {
                    fontSize: "18px",
                    marginLeft: "10px",
                  },
                  [theme.breakpoints.up("md")]: {
                    fontSize: "18px",
                    marginLeft: "10px",
                  },
                }}
              >
                Additional Information :{" "}
              </Box>
              <Box className=""></Box>
            </Box>
            <form>
              <Box style={{ padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="building"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Building :
                    </InputLabel>
                    <Input
                      id="building"
                      name="address_building"
                      onChange={Handelchange}
                      value={additionalInformation.address_building}
                      placeholder="Enter your building name"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="institute"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Institute Name :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.institute_name}
                      id="institute"
                      name="institute_name"
                      placeholder="Enter your institution name"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="father_name"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Father’s Name :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.fathers_name}
                      id="father_name"
                      name="fathers_name"
                      placeholder="Enter your father name"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="father_occupation"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Father’s Occupation :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.fathers_occupation}
                      id="father_occupation"
                      name="fathers_occupation"
                      placeholder="Enter your father's occupation"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="local_guardian_name"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Local Guardian Name :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.local_guardian_name}
                      id="local_guardian_name"
                      name="local_guardian_name"
                      placeholder="Enter local guardian name"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="local_guardian_occupation"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Local Guardian Occupation :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.local_guardian_occupation}
                      id="local_guardian_occupation"
                      name="local_guardian_occupation"
                      placeholder="Enter local guardian occupation"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="mother_name"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Mother’s Name :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.mother_name}
                      id="mother_name"
                      name="mother_name"
                      placeholder="Enter your father's occupation"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="mother_occupation"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Mother’s Occupation :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.mothers_occupation}
                      id="mother_occupation"
                      name="mothers_occupation"
                      placeholder="Enter mother's occupation"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="permanent_address"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Permanent Address :
                    </InputLabel>
                    <Input
                      id="permanent_address"
                      name="permanent_address"
                      onChange={Handelchange}
                      value={additionalInformation.permanent_address}
                      placeholder="Enter your permanent address"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                </Grid>{" "}
                <Grid container spacing={2} mt={2}>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="local_guardian_relation"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      Relation with Local Guardian :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.relation_local_guardian}
                      id="local_guardian_relation"
                      name="relation_local_guardian"
                      placeholder="Enter relation with local guardian"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="state"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      State :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.state}
                      id="state"
                      name="state"
                      placeholder="Enter your state"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel
                      htmlFor="city"
                      sx={{ fontWeight: "700", coloe: "#384D6C" }}
                    >
                      City :
                    </InputLabel>
                    <Input
                      onChange={Handelchange}
                      value={additionalInformation.city}
                      id="city"
                      name="city"
                      placeholder="Enter your city"
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",

                        display: "flex",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
          <Box
            container
            sx={{
              border: "1px solid #D0D0D0",
              width: "90%",
              margin: "25px auto",
            }}
          ></Box>

          <Grid container justifyContent={"center"} spacing={0}>
            <Grid item md={3} xs={6} className="d-flex justify-content-center">
              <button
                style={{
                  backgroundColor: loading ? "gray" : "#384D6C",
                  border: "1px solid #D1D5DB",
                  borderRadius: "8px",
                  fontWeight: "700",
                  color: "#ffff",
                  width: "160px",
                  padding: "10px 10px",
                }}
                disabled={loading}
                onClick={submitHandler}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </Grid>
          </Grid>
          <Box
            container
            sx={{
              border: "1px solid #D0D0D0",
              width: "90%",
              margin: "25px auto",
            }}
          ></Box>

          <Modal onClose={() => setShowModel(false)} open={shoModel}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "#EEEEFF",
                boxShadow: 24,
                p: 2,
                borderRadius: "8px",
                width: "95%",
                [theme.breakpoints.up("md")]: {
                  width: "60%",
                },
              }}
            >
              <Box
                sx={{
                  marginY: "10px",
                  marginX: "20px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: { xs: "18px", md: "23px" },
                    fontWeight: "700",
                    color: "#384D6C",
                  }}
                >
                  Update kyc
                </Typography>
              </Box>
              <Box className="" sx={{ px: { md: "20px", xs: "0px" } }}>
                <Box className="mt-4 px-5">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="h6" className="text-center mb-3">
                        Profile Upload
                      </Typography>
                      <Paper
                        onClick={handleClickForProfile}
                        sx={{
                          backgroundColor: "#DEDEDE",
                          borderRadius: "10px",
                          cursor: "pointer",
                          position: "relative",
                          width: "100%",
                          height: { xs: "100px", md: "250px" },
                        }}
                      >
                        <Box className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                          {previewImageProfile ? (
                            <>
                              <DeleteIcon
                                onClick={clearProfileImage}
                                fontSize="large"
                                style={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                  color: "red",
                                }}
                              />
                              <img
                                src={previewImageProfile}
                                alt="Preview"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <CloudUploadIcon
                                fontSize="large"
                                style={{ color: "#384D6C" }}
                              />
                              <input
                                type="file"
                                name="file"
                                onChange={profileFileHandler}
                                ref={profileRef}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Typography variant="h6" className="text-center mb-3">
                        Aadhar{" "}
                        <span
                          style={{
                            backgroundColor: "yellow",
                            textDecoration: "underline",
                          }}
                        >
                          Front
                        </span>
                      </Typography>
                      <Paper
                        onClick={handleClickForAdharFront}
                        sx={{
                          backgroundColor: "#DEDEDE",
                          borderRadius: "10px",
                          cursor: "pointer",
                          position: "relative",
                          width: "100%",
                          height: { xs: "100px", md: "250px" },
                        }}
                      >
                        <Box className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                          {previewImageAdharFront ? (
                            <>
                              <DeleteIcon
                                onClick={clearAdharFrontImage}
                                fontSize="large"
                                style={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                  color: "red",
                                }}
                              />
                              <img
                                src={previewImageAdharFront}
                                alt="Preview"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <CloudUploadIcon
                                fontSize="large"
                                style={{ color: "#384D6C" }}
                              />
                              <input
                                type="file"
                                name="file"
                                onChange={adharFrontFileHandler}
                                ref={adharFrontRef}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Typography variant="h6" className="text-center mb-3">
                        Aadhar{" "}
                        <span
                          style={{
                            backgroundColor: "yellow",
                            textDecoration: "underline",
                          }}
                        >
                          Back
                        </span>
                      </Typography>
                      <Paper
                        onClick={handleClickForAdharBack}
                        sx={{
                          backgroundColor: "#DEDEDE",
                          borderRadius: "10px",
                          cursor: "pointer",
                          position: "relative",
                          width: "100%",
                          height: { xs: "100px", md: "250px" },
                        }}
                      >
                        <Box className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                          {previewImageAdharBack ? (
                            <>
                              <DeleteIcon
                                onClick={clearAdharBackImage}
                                fontSize="large"
                                style={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                  color: "red",
                                }}
                              />
                              <img
                                src={previewImageAdharBack}
                                alt="Preview"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <CloudUploadIcon
                                fontSize="large"
                                style={{ color: "#384D6C" }}
                              />
                              <input
                                type="file"
                                name="file"
                                onChange={adharBackFileHandler}
                                ref={adharBackRef}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Box className="d-flex justify-content-center">
                        <Button
                          style={{
                            backgroundColor: loading ? "gray" : "#384D6C",
                            border: "1px solid #D1D5DB",
                            borderRadius: "8px",
                            fontWeight: "700",
                            color: "#ffff",
                            width: "160px",
                            padding: "10px 10px",
                          }}
                          onClick={uploadImg}
                          variant="contained"
                          className="m-2"
                          disabled={loading}
                        >
                          {loading ? "Uploading..." : "Upload"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setShowModel(false)}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Modal>

          <Box
            className=""
            sx={{
              fontWeight: "700",
              color: "#384D6C",
              fontSize: "20px",
              [theme.breakpoints.up("xs")]: {
                fontSize: "18px",
                marginLeft: "10px",
              },
              [theme.breakpoints.up("md")]: {
                fontSize: "18px",
                marginLeft: "80px",
              },
            }}
          >
            KYC Details :
          </Box>
          <Box className="mt-4 px-5">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" className="text-center mb-3">
                  Profile
                </Typography>
                <Paper
                  sx={{
                    backgroundColor: "#DEDEDE",
                    borderRadius: "10px",
                    cursor: "pointer",
                    position: "relative",
                    width: { xs: "95%", md: "80%" },
                    height: "200px",
                    marginX: "auto",
                  }}
                >
                  <Box className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                    <img
                      src={getProfileImage}
                      alt="Profile Image"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "fit",
                      }}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" className="text-center mb-3">
                  Aadhar Front
                </Typography>
                <Paper
                  sx={{
                    backgroundColor: "#DEDEDE",
                    borderRadius: "10px",
                    cursor: "pointer",
                    position: "relative",
                    width: { xs: "95%", md: "80%" },
                    height: "200px",
                    marginX: "auto",
                  }}
                >
                  <Box className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                    <img
                      src={getAdharFrontImage}
                      alt="Profile Image"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "fit",
                      }}
                    />
                  </Box>
                </Paper>
              </Grid>{" "}
              <Grid item xs={12} md={4}>
                <Typography variant="h6" className="text-center mb-3">
                  Aadhar Back
                </Typography>
                <Paper
                  sx={{
                    backgroundColor: "#DEDEDE",
                    borderRadius: "10px",
                    cursor: "pointer",
                    position: "relative",
                    width: { xs: "95%", md: "80%" },
                    height: "200px",
                    marginX: "auto",
                  }}
                >
                  <Box className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                    <img
                      src={getAdharBackImage}
                      alt="Profile Image"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "fit",
                      }}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box className="d-flex justify-content-center">
                  <Button
                    style={{
                      backgroundColor: "#384D6C",
                      border: "1px solid #D1D5DB",
                      borderRadius: "8px",
                      fontWeight: "700",
                      color: "#ffff",
                      width: "160px",
                      padding: "10px 10px",
                    }}
                    onClick={() => setShowModel(true)}
                    variant="contained"
                    className="m-2"
                  >
                    Edit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* <ToastContainer /> */}
    </>
  );
};

export default StudentEditProfile;
