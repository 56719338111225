import {
  Avatar,
  Box,
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Modal,
  Grid,
  TextField,

} from "@mui/material";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { getApiUrl } from "../utils/getApiUrl";
import { getUserFromLocalStorage } from "../service/localstorage";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 28,
  p: 4,
};

const Dashboard = () => {
  const [Student, setStudent] = useState([]);
  const [hostel, setHostel] = useState([]);
  const URL = getApiUrl();
  const theme = useTheme();
  const [order, setOrder] = useState([]);
  const [filterStudent, setFilteredStudents] = useState([]);
  const [pendingStudent, setPendingStudent] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageData, setPageData] = useState([]);
  const [orgaNizationDetails, setOrgaNizationDetails] = useState({});
  const user = getUserFromLocalStorage();
  const token = user?.token;
  const tokenType = user?.tokenType;

  const [overdueAllocations, setOverdueAllocations] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  ////////////////////////////////
  const [allOccupantData, setAllOccupantData] = useState([]);
  const [selectedOccupantId, setSelectedOccupantId] = useState("");
  const [currentSite, setCurrentSite] = useState("");
  const [allSiteAndNameData, setAllSiteAndNameData] = useState([]);
  const [selectedHostel, setSelectedHostel] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState("");
  const [loading, setLoading] = useState(false);
  const [customRoomRent, setCustomRoomRent] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [sendDueDateToBackend, setSendDueDateToBackend] = useState("");
  const [orderId, setOrderId] = useState("");
  const [currentObj, setCurrentObj] = useState({});
  const [overDueStudentName, setOverDueStudentName] = useState("");
  const [overDueHostelName, setOverDueHostelName] = useState("");
  const [overDueRoomName, setOverDueRoomName] = useState("");

  useEffect(() => {
    const today = new Date();
    const defaultDueDate = new Date();
    defaultDueDate.setDate(today.getDate() + 30);

    const formattedDueDateToShowOnFrontend = defaultDueDate
      .toISOString()
      .split("T")[0];
    const formattedDueDateToSendOnBackend = defaultDueDate.toISOString();
    setDueDate(formattedDueDateToShowOnFrontend);
    setSendDueDateToBackend(formattedDueDateToSendOnBackend);
  }, []);

  const getAllSiteAndSpaceData = async () => {
    const apiUrl = `${URL}/room/hostels_and_rooms`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `${tokenType} ${token}`,
        },
      });
      if (response) {
        toast.success("Hostel data fetched successfully!", {
          icon: "🟢",
          position: "top-right",
          autoClose: 3000,
        });
        setAllSiteAndNameData(response.data);
      }
    } catch (e) {
      console.log(`Error: ${e}`);
      toast.error("Failed to fetch site data!", {
        icon: "❌",
        position: "top-right",
        autoClose: 3000,
      });
    }
  };
  const getOccupantBasicInfo = async () => {
    const apiUrl = `${URL}/student/basic-info`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `${tokenType} ${token}`,
        },
      });
      if (response) {
        toast.success("Student data fetched successfully!", {
          icon: "🟢",
          position: "top-right",
          autoClose: 3000,
        });
        setAllOccupantData(response.data);
      }
    } catch (e) {
      console.log(`Error: ${e}`);
      toast.error("Failed to fetch student data!", {
        icon: "❌",
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getOccupantBasicInfo();
    getAllSiteAndSpaceData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    if (!selectedOccupantId) {
      toast.error("Please select a student.");
      setLoading(false);
      return;
    }

    if (!currentSite) {
      toast.error("Please select a Site.");
      setLoading(false);
      return;
    }
    if (!selectedSpace) {
      toast.error("Please select a room.");
      setLoading(false);
      return;
    }

    if (!customRoomRent) {
      toast.error("Please select a valid rent.");
      setLoading(false);
      return;
    }

    if (!orderId) {
      toast.error("Please Enter Order Id.");
      setLoading(false);
      return;
    }
    if (!dueDate) {
      toast.error("Please Enter Due Date.");
      setLoading(false);
      return;
    }

    const apiUrl = `${URL}/allocation/direct_allocate_room?student_id=${selectedOccupantId}&hostel_name=${currentSite}&room_name=${selectedSpace}&order_id=${orderId}&room_rent=${customRoomRent}&payment_due_on=${sendDueDateToBackend}`;
    try {
      const response = await axios.post(apiUrl, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      toast.success("Form submitted successfully");
      setSelectedOccupantId("");
      setSelectedSpace("");
      setCurrentSite("");
      setOrderId("");
      setCustomRoomRent("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.detail === "The room is already occupied"
      ) {
        toast.error("The room is already fully occupied");
      } else if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message ===
          "An allocation request with this order_id already exists."
      ) {
        toast.error("An allocation request with this order id already exists.");
      } else {
        console.error("Error:", error);
      }
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleSpaceChange = (event) => {
    const spaceName = event.target.value;
    setSelectedSpace(spaceName);

    // Find the selected room and its rent from the selected site
    const selectedRoom = selectedHostel.rooms.find(
      (room) => room.room_name === spaceName
    );
    if (selectedRoom) {
      setCustomRoomRent(selectedRoom.room_rent.toString());
    } else {
      setCustomRoomRent("");
    }
  };

  const handleSiteChange = (event) => {
    const siteName = event.target.value;
    setCurrentSite(siteName);
    const site = allSiteAndNameData.find(
      (site) => site.hostel_name === siteName
    );
    setSelectedHostel(site);
    setSelectedSpace("");
  };

  useEffect(() => {
    fetchOverdueAllocations();
  }, []);

  const fetchOverdueAllocations = async () => {
    try {
      const response = await fetch(`${URL}/allocation/overdue_allocations`);
      const data = await response.json();
      setOverdueAllocations(data);
    } catch (error) {
      console.error("Error fetching overdue allocations:", error);
    }
  };

  const handleDeallocate = async (orderId) => {
    try {
      const response = await axios.put(
        `${URL}/allocation/verify_allocation`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            order_id: orderId,
            // transaction_verification_status: false,
            allotted: false,
          },
        }
      );

      if (response) {
        toast.success("Deallocation successful!");
        fetchOverdueAllocations();
      } else {
        toast.error("Failed to deallocate room. Please try again.");
      }
    } catch (error) {
      console.error("Error deallocating room:", error);
      toast.error(
        "An error occurred while deallocating the room. Please try again."
      );
    }
  };

  const getStudents = async () => {
    const url = `${URL}/student/?page=${1}`;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(url, config);
      setStudent(res?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getHostal = async () => {
    const url = `${URL}/hostel/?page=${1}`;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(url, config);
      setHostel(res?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getAllocationverification = async () => {
    const url = `${URL}/allocation/allocation_requests`;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(url, config);
      setOrder(res?.data);
    } catch (error) {
      console.log(error?.message);
    }
  };
  useEffect(() => {
    const filterStudent = order?.filter((obj) => {
      if (obj.allotted === null) {
        return obj;
      }
    });
    setFilteredStudents(filterStudent);
  }, [order]);

  useEffect(() => {
    const getOrganizationDetails = async () => {
      const apiUrl = `${URL}/sitesettings/organization`;
      try {
        const response = await axios.get(apiUrl);
        if (response.status === 200) {
          setOrgaNizationDetails(response.data);
          // console.log("Logo URL:", response); // Add this line to check the URL
        } else {
          console.log("Unexpected response status:", response.data.status);
        }
      } catch (error) {
        console.log("Error:", error.message);
      }
    };

    getOrganizationDetails();
  }, [URL]);

  useEffect(() => {
    getStudents();
    getHostal();
    getAllocationverification();
  }, []);

  useEffect(() => {
    const filterStudent = Student?.filter((obj) => {
      if (obj.verified === null) {
        return obj;
      }
    });
    setPendingStudent(filterStudent);
  }, [Student]);

  const handelDeny = async (id) => {
    const url = `${URL}/allocation/verify_allocation?order_id=${id}&transaction_verification_status=false&allotted=false`;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(url, config, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res?.status === 200) {
        toast.success("Deny SuccessFully");
        getAllocationverification();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const handelApprov = async (id) => {
    const url = `${URL}/allocation/verify_allocation?order_id=${id}&transaction_verification_status=true&allotted=true`;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(url, config, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res?.status === 200) {
        toast.success("Approved Successfully");
        getAllocationverification();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, pendingStudent?.length);
    const dataForPage = pendingStudent?.slice(startIndex, endIndex);
    setPageData(dataForPage);
  }, [page, rowsPerPage, pendingStudent]);

  return (
    <>
      <Box
        bgcolor={"#EEEEFF"}
        sx={{
          [theme.breakpoints.up("xs")]: {
            marginLeft: "0px",
            padding: "20px 10px",
          },
          [theme.breakpoints.up("md")]: {
            marginLeft: "265px",
            padding: "25px 10px",
          },
          [theme.breakpoints.up("sm")]: {
            marginLeft: "265px",
            padding: "20px 10px",
          },
        }}
      >
        <Box>
          <p
            className="container"
            style={{ fontSize: "20px", fontWeight: "700", color: "#384D6C" }}
          >
            Welcome, Admin !
          </p>
        </Box>
        <Box
          component={"div"}
          sx={{
            bgcolor: "#fff",
            width: { xs: "95%", sm: "96%", lg: "98%" },
            height: { xs: 500, sm: 350, lg: 220 },
            borderRadius: "8px",
            margin: { xs: "0 10px", sm: "0 10px", lg: "0 10px" },
            padding: { xs: "10px 30px", sm: "10px 30px", lg: "0 30px" },
            display: "flex",
            justifyContent: {
              xs: "center",
              sm: "space-between",
              lg: "space-between",
            },
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              margin: { xs: "10px  80px", sm: "0" },
            }}
          >
            <Avatar
              className="d-block mx-auto"
              alt="Remy Sharp"
              src={orgaNizationDetails?.logo_url}
              sx={{
                width: 150,
                height: 150,
                borderRadius: "10px",
              }}
            />
          </Box>
          <Box sx={{ width: { sm: "100%", lg: 300 } }}>
            <Typography
              sx={{
                color: "#384D6C",
                fontSize: "20px",
                fontWeight: "bold",
                margin: "10px 0",
                textAlign: { xs: "center", lg: "justify" },
              }}
            >
              Welcome to your dashboard
            </Typography>
            <Typography
              sx={{
                textAlign: { xs: "center", lg: "justify" },
                color: "#384D6C",
                fontSize: "15px",
                width: { sm: "100%", lg: 300 },
              }}
            >
              This is{" "}
              <span className="fw-bold">
                {orgaNizationDetails?.name ? orgaNizationDetails?.name : "NA"}
              </span>{" "}
              admin dashboard designed to reflect an overview of the most
              important events inside the panel.
            </Typography>
          </Box>

          <Box>
            <Typography
              color={"#384D6C"}
              fontWeight={"bold"}
              fontSize={"20px"}
              textAlign={""}
            >
              Total Hostels: {hostel.length}
            </Typography>

            <Typography
              color={"#384D6C"}
              fontWeight={"bold"}
              fontSize={"20px"}
              textAlign={""}
            >
              Total Students: {Student.length}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "98%",
            // minHeight: 400,
            background: "#fff",
            borderRadius: "8px",
            margin: { xs: "30px 5px", lg: "30px 10px", sm: "30px 10px" },
            padding: "10px 0px",
          }}
        >
          <Typography
            sx={{
              margin: "10px 30px",
              color: "#384D6C",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Pending Requests
            <Box
              component={"span"}
              sx={{
                margin: "0px 20px",
                bgcolor: "#B4CBF6",
                padding: "10px 15px",
                borderRadius: "50%",
              }}
            >
              {filterStudent.length}
            </Box>
          </Typography>
          <Divider
            sx={{
              width: "100%",
              height: 2,
              bgcolor: "#CFCDCD",
            }}
          />
          {filterStudent?.map((obj) => {
            const { hostel_name, room_name, order_id, student_id, room_rent } =
              obj;
            return (
              <>
                <Box
                  sx={{
                    width: { xs: "98%", lg: "97%", sm: "98%" },

                    border: "1px solid black",
                    margin: "20px auto",
                    borderRadius: "11px",
                    display: "flex",
                    justifyContent: { xs: "center", lg: "space-between" },
                    gap: "20px",
                    flexWrap: { xs: "wrap", lg: "nowrap" },
                  }}
                >
                  <Box
                    component={"div"}
                    sx={{
                      margin: "8px 30px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#989696",
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "15px 0",
                      }}
                    >
                      Student Name{" "}
                      <Box
                        component={"span"}
                        sx={{
                          marginLeft: "40px",
                          color: "#384D6C",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        {Student?.map((obj) => {
                          if (obj.student_id === student_id) {
                            return obj.name;
                          }
                        })}{" "}
                      </Box>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#989696",
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "15px 0",
                      }}
                    >
                      Contact no.{" "}
                      <Box
                        component={"span"}
                        sx={{
                          marginLeft: "60px",
                          color: "#384D6C",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        {Student?.map((obj) => {
                          if (obj.student_id === student_id) {
                            return obj.contact_no;
                          }
                        })}
                      </Box>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#989696",
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "15px 0",
                      }}
                    >
                      Hostel Name{" "}
                      <Box
                        component={"span"}
                        sx={{
                          marginLeft: "50px",
                          color: "#384D6C",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {"         "}
                        {hostel_name}{" "}
                      </Box>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#989696",
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "15px 0",
                      }}
                    >
                      Room No.{" "}
                      <Box
                        component={"span"}
                        sx={{
                          marginLeft: "76px",
                          color: "#384D6C",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        {room_name}{" "}
                      </Box>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#989696",
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "15px 0",
                      }}
                    >
                      Order ID/UTR
                      <Box
                        component={"span"}
                        sx={{
                          marginLeft: "45px",
                          color: "#384D6C",
                          fontSize: "16px",
                          fontWeight: "500",
                          wordBreak: "break-all",
                        }}
                      >
                        {order_id}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    component={"div"}
                    sx={{ margin: "20px 80px" }}
                    className="d-flex  justify-content-center gap-3 flex-column align-items-center"
                  >
                    <Link
                      to={`/studentprofile/${student_id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        style={{
                          width: "300px",
                          border: "1.5px solid #346feb",
                          borderRadius: "10px",
                          color: "#384D6C",
                          fontSize: "16px",
                          fontWeight: "bold",
                          background: "#fff",
                          margin: "0px auto",
                          display: "block",
                          padding: "7px 0",
                        }}
                      >
                        View Student Profile
                      </button>
                    </Link>
                    <button
                      style={{
                        width: "300px",
                        border: "1.5px solid black",
                        padding: "7px 0",
                        borderRadius: "10px",
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: "bold",
                        background: "#ff6b6b",
                        margin: "0px auto",
                        display: "block",
                      }}
                      onClick={() => {
                        handelDeny(order_id);
                      }}
                    >
                      Deny Room Allocation Request
                    </button>
                    <button
                      style={{
                        width: "300px",
                        border: "1.5px solid black",
                        padding: "7px 0",
                        borderRadius: "10px",
                        color: "#384D6C",
                        fontSize: "16px",
                        fontWeight: "bold",
                        background: "",
                        margin: "0px auto",
                        display: "block",
                      }}
                    >
                      Room Rent : ₹ {room_rent}/-{" "}
                    </button>
                    <button
                      style={{
                        width: "300px",
                        border: "1.5px solid black",
                        padding: "7px 0",
                        borderRadius: "10px",
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "bold",
                        margin: "0px auto",
                        display: "block",
                        background: "#384D6C",
                        margin: "0px 30px",
                        display: "block",
                      }}
                      onClick={() => {
                        handelApprov(order_id);
                      }}
                    >
                      Approve Room Allocation Request
                    </button>
                  </Box>
                </Box>
              </>
            );
          })}
        </Box>

        <Box
          sx={{
            width: "98%",
            background: "#fff",
            borderRadius: "8px",
            margin: { xs: "30px 5px", lg: "30px 10px", sm: "30px 10px" },
            padding: "10px 0px",
          }}
        >
          <Typography
            sx={{
              margin: "10px 30px",
              color: "#384D6C",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Overdue Allocations
            <Box
              component={"span"}
              sx={{
                margin: "0px 20px",
                bgcolor: "#B4CBF6",
                padding: "10px 15px",
                borderRadius: "50%",
              }}
            >
              {overdueAllocations.length}
            </Box>
          </Typography>
          <Divider
            sx={{
              width: "100%",
              height: 2,
              bgcolor: "#CFCDCD",
            }}
          />
          {overdueAllocations.map((obj) => {
            const {
              student_name,
              student_contact_no,
              hostel_name,
              room_name,
              order_id,
              rent_of_room,
              rent_modified,
              student_id,
            } = obj;
            return (
              <Box
                key={order_id}
                sx={{
                  width: { xs: "98%", lg: "97%", sm: "98%" },
                  border: "1px solid black",
                  margin: "20px auto",
                  borderRadius: "11px",
                  display: "flex",
                  justifyContent: { xs: "center", lg: "space-between" },
                  gap: "20px",
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                }}
              >
                <Box
                  component={"div"}
                  sx={{
                    margin: "8px 30px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#989696",
                      fontSize: "18px",
                      fontWeight: "bold",
                      margin: "15px 0",
                    }}
                  >
                    Student Name
                    <Box
                      component={"span"}
                      sx={{
                        marginLeft: "40px",
                        color: "#384D6C",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {student_name}
                    </Box>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#989696",
                      fontSize: "18px",
                      fontWeight: "bold",
                      margin: "15px 0",
                    }}
                  >
                    Contact no.
                    <Box
                      component={"span"}
                      sx={{
                        marginLeft: "60px",
                        color: "#384D6C",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {student_contact_no}
                    </Box>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#989696",
                      fontSize: "18px",
                      fontWeight: "bold",
                      margin: "15px 0",
                    }}
                  >
                    Hostel Name
                    <Box
                      component={"span"}
                      sx={{
                        marginLeft: "50px",
                        color: "#384D6C",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {hostel_name}
                    </Box>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#989696",
                      fontSize: "18px",
                      fontWeight: "bold",
                      margin: "15px 0",
                    }}
                  >
                    Room No.
                    <Box
                      component={"span"}
                      sx={{
                        marginLeft: "76px",
                        color: "#384D6C",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {room_name}
                    </Box>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#989696",
                      fontSize: "18px",
                      fontWeight: "bold",
                      margin: "15px 0",
                    }}
                  >
                    Order ID/UTR
                    <Box
                      component={"span"}
                      sx={{
                        marginLeft: "45px",
                        color: "#384D6C",
                        fontSize: "16px",
                        fontWeight: "500",
                        wordBreak: "break-all",
                      }}
                    >
                      {order_id}
                    </Box>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#989696",
                      fontSize: "18px",
                      fontWeight: "bold",
                      margin: "15px 0",
                    }}
                  >
                    Rent of Room
                    <Box
                      component={"span"}
                      sx={{
                        marginLeft: "45px",
                        color: "#384D6C",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      ₹ {rent_of_room}/-
                    </Box>
                  </Typography>
                </Box>
                <Box
                  component={"div"}
                  sx={{ margin: "20px 80px" }}
                  className="d-flex  justify-content-center gap-3 flex-column align-items-center"
                >
                  <Link
                    to={`/studentprofile/${student_id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      style={{
                        width: "300px",
                        border: "1.5px solid #346feb",
                        borderRadius: "10px",
                        color: "#384D6C",
                        fontSize: "16px",
                        fontWeight: "bold",
                        background: "#fff",
                        margin: "0px auto",
                        display: "block",
                        padding: "7px 0",
                      }}
                    >
                      View Student Profile
                    </button>
                  </Link>
                  <button
                    style={{
                      width: "300px",
                      border: "1.5px solid #346feb",
                      borderRadius: "10px",
                      color: "#384D6C",
                      fontSize: "16px",
                      fontWeight: "bold",
                      background: "#fff",
                      margin: "0px auto",
                      display: "block",
                      padding: "7px 0",
                    }}
                    onClick={() => {
                      handleOpen();
                      setCurrentObj(obj);
                      setOverDueStudentName(student_name);
                      setCustomRoomRent(rent_of_room);
                      setSelectedOccupantId(student_id);
                      setOverDueHostelName(hostel_name);
                      setOverDueRoomName(room_name);
                    }}
                  >
                    Reallocate Room
                  </button>
                  <button
                    style={{
                      width: "300px",
                      border: "1.5px solid black",
                      padding: "7px 0",
                      borderRadius: "10px",
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "bold",
                      background: "#ff6b6b",
                      margin: "0px auto",
                      display: "block",
                    }}
                    onClick={() => {
                      handleDeallocate(order_id);
                    }}
                  >
                    Deallocate Room
                  </button>
                  <button
                    style={{
                      width: "300px",
                      border: "1.5px solid black",
                      padding: "7px 0",
                      borderRadius: "10px",
                      color: "#384D6C",
                      fontSize: "16px",
                      fontWeight: "bold",
                      background: "",
                      margin: "0px auto",
                      display: "block",
                    }}
                  >
                    Modified Room Rent: ₹ {rent_modified}/-
                  </button>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Toaster />
        <Box
          sx={{
            width: "98%",
            // minHeight: 400,
            background: "#fff",
            borderRadius: "8px",
            margin: { xs: "30px 5px", lg: "30px 10px", sm: "30px 10px" },
            padding: "10px 0px",
          }}
        >
          <p
            style={{
              margin: "10px 30px",
              color: "#384D6C",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Pending Profile Verification Requests{" "}
          </p>
          <Divider
            sx={{
              width: "100%",
              height: 2,
              bgcolor: "#CFCDCD",
            }}
          />
          <TableContainer
            component={Paper}
            sx={{
              width: "98%",
              minHeight: 200,
              background: "#fff",
              borderRadius: "8px",
              margin: {
                xs: "30px 5px",
                lg: "30px 10px",
                sm: "30px 10px",
              },
              padding: "10px 0px",
            }}
          >
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Profile</TableCell>

                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageData?.length > 0 ? (
                  pageData?.map((obj) => {
                    return (
                      <>
                        <TableRow
                          key={obj.student_id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="center"
                            className="d-flex justify-content-start"
                            style={{ color: "#384D6C", fontWeight: "bold" }}
                          >
                            {" "}
                            <Box
                              className="d-flex"
                              sx={{ width: "200px", marginX: "auto" }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#D9D9D9",
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "26px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "26px",
                                  }}
                                  src={`${
                                    obj.profile_image
                                      ? obj.profile_image
                                      : "https://w7.pngwing.com/pngs/223/244/png-transparent-computer-icons-avatar-user-profile-avatar-heroes-rectangle-black.png"
                                  }`}
                                  alt="pic"
                                />
                              </div>
                              <div className="ms-2 mt-1">
                                {obj?.name}
                                <br />
                                <span
                                  style={{ color: "gray", fontSize: "12px" }}
                                >
                                  <LocalPhoneIcon
                                    sx={{ height: "13px", color: "#384D6C" }}
                                  />
                                  {obj?.contact_no || "Not available"}
                                </span>
                              </div>
                            </Box>
                          </TableCell>

                          <TableCell align="center">
                            <Link to={`/studentprofile/${obj.student_id}`}>
                              <Button
                                variant="outlined"
                                sx={{ whiteSpace: "nowrap" }}
                              >
                                View Profile
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No Students Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={pendingStudent?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <Typography
              sx={{
                color: "#989696",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              {overDueHostelName} of {overDueRoomName}
            </Typography>
          </Box>
          <Paper
            sx={{ width: { xs: "300px", md: "600px" }, marginX: "auto", mt: 4 }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-cener"
              >
                <TextField
                  sx={{ margin: 2 }}
                  label="Student name"
                  variant="outlined"
                  type="text"
                  fullWidth
                  id="dueDate"
                  name="dueDate"
                  value={overDueStudentName}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <TextField
                  sx={{ margin: 2 }}
                  label="Hostel name"
                  variant="outlined"
                  type="text"
                  fullWidth
                  id="overDueHostelName"
                  name="overDueHostelName"
                  value={overDueHostelName}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <TextField
                  sx={{ margin: 2 }}
                  label="Room name"
                  variant="outlined"
                  type="text"
                  fullWidth
                  id="overDueRoomName"
                  name="overDueRoomName"
                  value={overDueRoomName}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <TextField
                  sx={{ margin: 2 }}
                  label="Enter rent"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={customRoomRent}
                  onChange={(e) => setCustomRoomRent(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <TextField
                  sx={{ margin: 2 }}
                  label="Payment Due on"
                  variant="outlined"
                  type="date"
                  fullWidth
                  id="dueDate"
                  name="dueDate"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <TextField
                  sx={{ margin: 2 }}
                  label="Order Id"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <Button
                  sx={{ width: "300px", margin: 2 }}
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Submitting" : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};

export default Dashboard;
