import axios from "axios";
import { getApiUrl } from "../utils/getApiUrl";

const URL = getApiUrl();


export const addUserToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
  window.location.reload();
};

export const getUserFromLocalStorage = () => {
  const userData = localStorage.getItem("user");
  if (userData) {
    try {
      const user = JSON.parse(userData);
      console.log("User from local storage:", user);
      return user;
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
      return null;
    }
  }
  return null;
};



export async function isValidToken() {
  const tokenString = localStorage.getItem("user");
  const token = tokenString ? JSON.parse(tokenString) : null;
  const accessToken = token?.token;

  if (!accessToken) {
    console.log("No token found.");
    return { isValid: false, reason: "No token" };
  }

  try {
    const apiUrl = `${URL}/api/token/validate`;
    const response = await axios.post(apiUrl, accessToken);

    if (response.data.status === "valid") {
      return { isValid: true };
    } else {
      return {
        isValid: false,
        reason: "Your Token is expired now please loggin again",
      };
    }
  } catch (error) {
    console.error("Error during token validation:", error);
    return { isValid: false, reason: "Exception in request" };
  }
}
