import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  Button,
  IconButton,
  Modal,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import axios from "axios";
import EditLogo from "../../components/AdminSettings/ImageUpload";
import toast from "react-hot-toast";
import UploadPayment from "./../../components/AdminSettings/UploadPayment";
import ContactForm from "../../components/AdminSettings/ContactForm";
import OrganizationDetailsForm from "../../components/AdminSettings/OrganizationDetailsForm";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddAddminStafDetails from "../../components/AdminSettings/AddAddminStafDetails";
import EditAddminStafDetails from "../../components/AdminSettings/EditAddminStafDetails";
import ImageUpload from "../../components/AdminSettings/ImageUpload";
import EditHostelLaws from "../../components/AdminSettings/EditHostelLaws";
import DeleteIcon from "@mui/icons-material/Delete";
import { getUserFromLocalStorage } from "../../service/localstorage";
import { getApiUrl } from "../../utils/getApiUrl";

function AddminSetting() {
  const URL = getApiUrl();
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [signature, setSignature] = useState({});
  const [qrDetails, setQrDetails] = useState({});
  const [contactsDetails, setContactsDetails] = useState({});
  const [staffDetails, setStaffDetails] = useState([]);
  const [error, setError] = useState("");
  const theme = useTheme();
  const [language, setLanguage] = useState("english");
  const [hostelLaws, setHostelLaws] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editable, seteditable] = useState();
  const logoUploadApiUrl = `${URL}/sitesettings/logo`;
  const logoFieldName = "logo_file";

  const faviconUpdateUrl = `${URL}/sitesettings/favicon`;
  const faviconFieldName = "favicon_file";

  const signUpdateUrl = `${URL}/admin/signature`;
  const signFieldName = "file";

  const [currentStaffObject, setCurrentStaffObject] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  const apiStaffImgUrl = `${URL}/sitesettings/staff/${currentStaffObject?.name?.trim()}/profile-pic`;

  const onSuccess = async (response) => {
    if (response) {
      window.location.reload();
    }
    handleClose();
  };
  const renderContactDetail = (label, value) => {
    if (!value) return null;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          width: "100%"
        }}
        key={label}
      >
        <Typography
          sx={{
            fontSize: "11px",
            color: "#989696",
            fontWeight: "bold",
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            color: "#384D6C",
            fontWeight: "600",
            fontSize: "11px",
            wordBreak: "break-all",
          }}
        >
          {value}
        </Typography>
      </Box>
    );
  };


  const onFailure = (error) => {
    toast("Upload failed:");
    onFailure(error);
  };

  const onQrSuccess = (response) => {
    if (response.data) {
      window.location.reload();
      handleClose();
    }
  };

  const onStaffEditSuccess = (response) => {
    if (response.data) {
      window.location.reload();
      handleClose();
    }
  };

  const onQrFailure = (error) => {
    console.error("Upload failure:", error);
  };

  const handleOnsuccessOfAddStaff = async (response) => {
    if (response.data) {
      await fetchData();
    }
  };

  const handleOnlawsFormSuccess = (response) => {
    if (response.data.message) {
      window.location.reload();
      handleClose();
    }
  };

  const handleContactFormSuccess = (response) => {
    if (response.data && response.data.message) {
      handleClose();
      console.log(response.data.message); // Display the API success message
    } else {
      // Handle cases where the response does not contain the expected message
      console.error("Unexpected response structure:", response);
      alert(
        "Failed to update contact details. Please check the console for more details."
      );
    }
  };

  const handleOnDetailsSubmissionSuccess = (response) => {
    if (response) {
      setOrganizationDetails(response?.data);
      handleClose();
    }
  };


  const fetchOrganizationDetails = async () => {
    const response = await axios.get(`${URL}/sitesettings/organization`);
    return response.data;
  };

  const fetchSignature = async () => {
    const response = await axios.get(`${URL}/admin/signature`);
    return response.data;
  };

  const fetchQrDetails = async () => {
    const response = await axios.get(`${URL}/admin/qrcode`);
    return response.data;
  };

  const fetchContactsDetails = async () => {
    const response = await axios.get(`${URL}/sitesettings/contacts`);
    return response.data;
  };

  const fetchStaffDetails = async () => {
    const response = await axios.get(`${URL}/sitesettings/staff`);
    return response.data;
  };

  const fetchHostelLaws = async () => {
    const response = await axios.get(`${URL}/sitesettings/hostel/laws`);
    return response.data;
  };

  const fetchData = async () => {
    try {
      const organizationDetails = await fetchOrganizationDetails();
      const signature = await fetchSignature();
      const qrDetails = await fetchQrDetails();
      const contactsDetails = await fetchContactsDetails();
      const staffDetails = await fetchStaffDetails();
      const hostelLaws = await fetchHostelLaws();

      setOrganizationDetails(organizationDetails);
      setSignature(signature);
      setQrDetails(qrDetails);
      setContactsDetails(contactsDetails);
      setStaffDetails(staffDetails);
      setHostelLaws(hostelLaws);
    } catch (error) {
      console.error("Failed to fetch data", error);
      setError("Failed to load data.");
    }
  };

  const deletStaffByName = async (staffName) => {
    const apiUrl = `${URL}/sitesettings/staff/${staffName}`;
    try {
      const user = getUserFromLocalStorage();
      const token = user?.token;
      const response = await axios.delete(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      if (response.data.message) {
        toast.success(response.data.message);
        await fetchData();
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete staff. Please try again."); // Added user feedback
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Box
      bgcolor={"#EEEEFF"}
      sx={{
        [theme.breakpoints.up("xs")]: {
          marginLeft: "0px",
          padding: "10px 5px",
        },
        [theme.breakpoints.up("md")]: {
          marginLeft: "265px",
          padding: "15px 10px",
        },
      }}
    >
      <>
        <Box>
          <p
            className="container"
            style={{ fontSize: "20px", fontWeight: "700", color: "#384D6C" }}
          >
            Site Settings :
          </p>
        </Box>

        <Box className="mx-2">
          <Grid container spacing={2} justifyContent="center">
            <Grid
              item
              md={6}
              xs={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Paper
                sx={{
                  width: { xs: "95%", md: 360 },
                  height: 300,
                  margin: "0 auto",
                  m: "20px",
                  p: 2,
                }}
              >
                <Box className="h-100 d-flex justify-content-evenly align-items-center">
                  <Box sx={{ position: "relative" }}>
                    <Box>
                      <img
                        src={organizationDetails?.logo_url}
                        alt=""
                        height={"90px"}
                        className="d-bock mx-auto"
                      />
                      <BorderColorOutlinedIcon
                        onClick={() => {
                          handleOpen();
                          seteditable(1);
                        }}
                        style={{
                          position: "absolute",
                          top: "-30px",
                          right: "0px",
                          cursor: "pointer",
                          color: "#384D6C",
                        }}
                      />
                    </Box>
                    <Box className="mt-2 text-center">
                      <Typography
                        sx={{
                          [theme.breakpoints.up("xs")]: {
                            fontSize: "16px",
                            color: "#384D6C",
                            fontWeight: "bold",
                          },
                        }}
                      >
                        Site Logo
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ position: "relative" }}>
                    <Box>
                      <img
                        src={organizationDetails?.favicon_url}
                        alt=""
                        height={"90px"}
                        className="d-bock mx-auto"
                      />
                      <BorderColorOutlinedIcon
                        onClick={() => {
                          handleOpen();
                          seteditable(2);
                        }}
                        style={{
                          position: "absolute",
                          top: "-30px",
                          right: "0px",
                          cursor: "pointer",
                          color: "#384D6C",
                        }}
                      />
                    </Box>
                    <Box className="mt-2 text-center">
                      <Typography
                        sx={{
                          [theme.breakpoints.up("xs")]: {
                            fontSize: "16px",
                            color: "#384D6C",
                            fontWeight: "bold",
                          },
                        }}
                      >
                        Favicon icon
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >

              <Paper
                sx={{
                  width: { xs: "95%", md: 360 },

                  margin: "auto",
                  my: 2,
                  p: 2,
                  display: "flex",
                  flexDirection: "column",

                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: { xs: "row" },
                    textAlign: { xs: "left" },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#384D6C",
                      fontWeight: "bold",
                      fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                    }}
                  >
                    Contact Details
                  </Typography>
                  <IconButton
                    sx={{
                      color: "#384D6C",
                      mt: { xs: 0 },
                    }}
                    onClick={() => {
                      handleOpen();
                      seteditable(3);
                    }}
                  >
                    <BorderColorOutlinedIcon
                      sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}
                    />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mt: 2,
                    width: "100%",
                    px: { xs: 0, sm: 0 },
                    height: 200,
                    overflowY: "scroll",
                  }}
                >
                  {renderContactDetail('Phone No', contactsDetails?.phone)}
                  {renderContactDetail('WhatsApp No', contactsDetails?.whatsapp)}
                  {renderContactDetail('Email', contactsDetails?.email)}
                  {renderContactDetail('Facebook', contactsDetails?.facebook)}
                  {renderContactDetail('LinkedIn', contactsDetails?.linkedin)}
                  {renderContactDetail('Twitter', contactsDetails?.twitter)}
                  {renderContactDetail('Instagram', contactsDetails?.instagram)}
                  {renderContactDetail('Telegram', contactsDetails?.telegram)}
                  {renderContactDetail('Snapchat', contactsDetails?.snapchat)}
                  {renderContactDetail('YouTube', contactsDetails?.youtube)}
                  {renderContactDetail('TikTok', contactsDetails?.tiktok)}
                  {renderContactDetail('Discord', contactsDetails?.discord)}
                  {renderContactDetail('Pinterest', contactsDetails?.pinterest)}
                  {renderContactDetail('Reddit', contactsDetails?.reddit)}
                  {renderContactDetail('Skype', contactsDetails?.skype)}
                  {renderContactDetail('Zoom', contactsDetails?.zoom)}
                  {renderContactDetail('Google Meet', contactsDetails?.google_meet)}
                  {renderContactDetail('Microsoft Teams', contactsDetails?.microsoft_teams)}
                  {renderContactDetail('Website', contactsDetails?.website)}
                </Box>
              </Paper>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Paper
                sx={{
                  width: { xs: "95%", md: 360 },
                  height: 300,
                  margin: "0 auto",
                  m: "20px",
                  p: 2,
                }}
              >
                <Box className="d-flex justify-content-between">
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#384D6C",
                      fontWeight: "bold",
                    }}
                  >
                    Receipt Signature
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#384D6C",
                      fontWeight: "bold",
                    }}
                  >
                    <BorderColorOutlinedIcon
                      onClick={() => {
                        handleOpen();
                        seteditable(4);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "#384D6C",
                      }}
                    />
                  </Typography>
                </Box>
                <Box className="h-100 d-flex justify-content-evenly align-items-center">
                  <Box>
                    <Box>
                      <img
                        src={signature?.signature_url}
                        alt=""
                        height={"150px"}
                        className="d-bock mx-auto"
                      />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                sx={{
                  width: { xs: "95%", md: 360 },
                  minHeight: 300,
                  margin: "auto",
                  m: 2,
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "#384D6C", fontWeight: "bold" }}
                  >
                    Organisation Details
                  </Typography>
                  <IconButton
                    sx={{ color: "#384D6C" }}
                    onClick={() => {
                      handleOpen();
                      seteditable(5);
                    }}
                  >
                    <BorderColorOutlinedIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mt: 2,
                    width: "100%",
                    px: 4,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#989696",
                        fontWeight: "bold",
                        minWidth: "100px",
                      }}
                    >
                      Name :
                    </Typography>
                    <Typography sx={{ color: "#384D6C", fontWeight: "600" }}>
                      {organizationDetails?.name || "NA"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#989696",
                        fontWeight: "bold",
                        minWidth: "100px",
                      }}
                    >
                      Address :
                    </Typography>
                    <Typography sx={{ color: "#384D6C", fontWeight: "600" }}>
                      {organizationDetails?.address || "NA"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#989696",
                        fontWeight: "bold",
                        minWidth: "100px",
                      }}
                    >
                      Email :
                    </Typography>
                    <Typography
                      sx={{
                        color: "#384D6C",
                        fontWeight: "600",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                sx={{
                  width: { xs: "95%", md: 360 },
                  height: 400,
                  margin: "auto",
                  m: 2,
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "#384D6C", fontWeight: "bold" }}
                  >
                    Payment Details
                  </Typography>
                  <IconButton
                    sx={{ color: "#384D6C" }}
                    onClick={() => {
                      handleOpen();
                      seteditable(6);
                    }}
                  >
                    <BorderColorOutlinedIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 0,
                    mt: 2,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "evenly",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Avatar src={organizationDetails?.logo_url} />
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "#384D6C",
                        fontWeight: "bold",
                      }}
                    >
                      {organizationDetails?.name || "NA"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: 3,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: "#989696",
                        width: { xs: "180px", md: "175px" },
                        height: { xs: "180px", md: "175px" },
                      }}
                    >
                      <Avatar
                        src={qrDetails?.qr_code_url}
                        sx={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 0,
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        mt: 3,
                        color: "#384D6C",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      UPI ID : {qrDetails?.upi_id || "NA"}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                sx={{
                  width: { xs: "95%", md: 360 },
                  height: 500,
                  margin: "0 auto",
                  my: 2,
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  overflowY: "auto", // Enable vertical scrolling
                  "&::-webkit-scrollbar": {
                    width: "8px", // Set width of the scrollbar
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#ccc", // Color of the scroll thumb
                    borderRadius: "4px", // Border radius of the scroll thumb
                  },
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h6"
                    sx={{ color: "#384D6C", fontWeight: "bold" }}
                  >
                    Add Staff
                  </Typography>
                  <Typography variant="h6">
                    <IconButton
                      onClick={() => {
                        handleOpen();
                        seteditable(7);
                      }}
                    >
                      <AddBoxIcon
                        sx={{ color: "#384D6C", fontWeight: "bold" }}
                      />
                    </IconButton>
                  </Typography>
                </Box>
                <Box className="wrapper">
                  {staffDetails.map((staffDetail, index) => (
                    <div key={index} className="mb-3 shadow p-3">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ color: "#384D6C", fontWeight: "bold" }}
                        ></Typography>

                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <IconButton
                            sx={{ color: "red" }}
                            onClick={() => {
                              deletStaffByName(staffDetail.name);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>{" "}
                          <IconButton
                            sx={{ color: "#384D6C" }}
                            onClick={() => {
                              handleOpen();
                              seteditable(9);
                              setCurrentStaffObject(staffDetail);
                            }}
                          >
                            <BorderColorOutlinedIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 0,
                          mt: 0,
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            display: "inline-block",
                            "&:hover > #editButton": {
                              display: "flex", // Show button on hover
                            },
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <Avatar
                            src={staffDetail?.profile_pic_url}
                            sx={{ height: 120, width: 120 }}
                          />
                          <IconButton
                            onClick={() => {
                              handleOpen();
                              seteditable(10);
                              setCurrentStaffObject(staffDetail);
                            }}
                            id="editButton"
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              display: "none",
                              zIndex: 2,
                            }}
                            variant="contained"
                            color="primary"
                          >
                            <Box
                              sx={{
                                bgcolor: "#1d1a1a8a",
                                height: 120,
                                width: 120,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 50,
                              }}
                            >
                              {" "}
                              <BorderColorOutlinedIcon
                                style={{ color: "white" }}
                              />
                            </Box>
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            alignItems: "start",
                            width: "100%",
                            px: 4,
                            marginTop: { xs: 1, md: 2 },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: "#989696",
                                fontWeight: "bold",
                              }}
                            >
                              Name :
                            </Typography>
                            <Typography
                              sx={{
                                color: "#384D6C",
                                fontWeight: "600",
                                fontSize: "14px",
                                marginLeft: "10px",
                              }}
                            >
                              {""}{" "}
                              {staffDetail?.name ? staffDetail?.name : "NA"}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: "#989696",
                                fontWeight: "bold",
                              }}
                            >
                              Position :
                            </Typography>
                            <Typography
                              sx={{
                                color: "#384D6C",
                                fontWeight: "600",
                                fontSize: "14px",
                                marginLeft: "10px",
                              }}
                            >
                              {staffDetail?.position
                                ? staffDetail?.position
                                : "NA"}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: "#989696",
                                fontWeight: "bold",
                              }}
                            >
                              Contact No :
                            </Typography>
                            <Typography
                              sx={{
                                color: "#384D6C",
                                fontWeight: "600",
                                fontSize: "14px",
                                marginLeft: "10px",
                              }}
                            >
                              {staffDetail?.mobile_number
                                ? staffDetail?.mobile_number
                                : "NA"}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: "#989696",
                                fontWeight: "bold",
                              }}
                            >
                              Email :
                            </Typography>
                            <Typography
                              sx={{
                                color: "#384D6C",
                                fontWeight: "600",
                                fontSize: "12px",
                                marginLeft: "10px",
                              }}
                            >
                              {staffDetail?.email_address
                                ? staffDetail?.email_address
                                : "NA"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  ))}
                </Box>
              </Paper>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                elevation={4}
                className="m-3 rounded-3  "
                sx={{
                  width: { xs: "95%", md: 370 },
                  margin: "auto",
                  m: 2,
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  position: "relative",
                }}
              >
                <Box
                  component={"section"}
                  sx={{
                    px: { md: "20px", xs: "0px" },
                    py: { md: "20px", xs: "10px" },
                  }}
                >
                  <Box
                    className=" py-2"
                    sx={{ px: { md: "20px", xs: "10px" } }}
                  >
                    <Box>
                      <Box
                        sx={{
                          marginY: "10px",
                          marginX: "20px",
                        }}
                      >
                        <Box className="d-flex justify-content-between align-items-center  w-100">
                          <Typography
                            variant="h6"
                            color={"#384D6C"}
                            className="fw-bold"
                            sx={{
                              fontWeight: "bold",
                              [theme.breakpoints.down("sm")]: {
                                fontSize: "16px",
                              },
                            }}
                          >
                            Hostel Laws{" "}
                            <IconButton
                              onClick={() => {
                                handleOpen();
                                seteditable(11);
                              }}
                              sx={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                cursor: "pointer",
                                color: "#384D6C",
                              }}
                            >
                              <BorderColorOutlinedIcon />
                            </IconButton>
                          </Typography>
                          <select
                            name="language"
                            id="language"
                            className="form-control w-25"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                          >
                            <option value="english">English</option>
                            <option value="other_language">Other</option>
                          </select>
                        </Box>
                      </Box>

                      {language === "english" && hostelLaws?.english && (
                        <Box
                          sx={{
                            overflowY: "scroll",
                            scrollbarWidth: "auto",
                            scrollbarColor: "#384D6C transparent",
                            width: {
                              xs: "100%",
                            },
                            margin: "0 auto",
                            height: {
                              xs: "400px",
                              md: "300px",
                            },
                          }}
                        >
                          <Typography>
                            {hostelLaws?.english
                              .split("\n")
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                          </Typography>
                        </Box>
                      )}

                      {language === "other_language" && (
                        <Box
                          sx={{
                            overflowY: "scroll",
                            scrollbarWidth: "auto",
                            scrollbarColor: "#384D6C transparent",
                            width: {
                              xs: "100%",
                            },
                            margin: "0 auto",
                            height: {
                              xs: "400px",
                              md: "300px",
                            },
                          }}
                        >
                          <Typography>
                            {hostelLaws?.other_language
                              .split("\n")
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            ></Grid>
          </Grid>
        </Box>
      </>
      <Modal onClose={handleClose} open={open}>
        <Box
          sx={{
            [theme.breakpoints.up("xs")]: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#f0f0f0",
              boxShadow: 24,
              padding: "15px",
              fontFamily: "Arial, sans-serif",
              borderRadius: "8px",
              width: "95%",
            },
            [theme.breakpoints.up("md")]: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#f0f0f0",
              boxShadow: 24,
              padding: "20px",
              fontFamily: "Arial, sans-serif",
              borderRadius: "8px",
              width: "60%",
            },
          }}
        >
          <Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    xs: "16px",
                    md: "22px",
                    color: "#384D6C",
                    fontWeight: "bold",
                  },
                }}
              >
                {editable === 1 && "Upload Logo"}
                {editable === 2 && "Favicon icon"}
                {editable === 3 && "Contact Details"}
                {editable === 4 && "Receipt Signature"}
                {editable === 5 && "Organisation Details"}
                {editable === 6 && "Payment Details"}
                {editable === 7 && "Add Staff"}
                {editable === 8 && "Hostel Laws"}
                {editable === 9 && "Edit"}
                {editable === 10 && `Edit ${currentStaffObject?.name} Profile`}
                {editable === 11 && "Edit hostel laws"}
              </Typography>
            </Box>
            <Box
              sx={{ margin: "10px" }}
              className="d-flex justify-content-center  align-items-center "
            >
              {editable === 1 && (
                <Box>
                  <EditLogo
                    uploadUrl={logoUploadApiUrl}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    fieldName={logoFieldName}
                  />

                  <Box className="mt-3 w-100">
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "16px",
                          color: "#384D6C",
                          fontWeight: "bold",
                          textAlign: "center",
                        },
                      }}
                    >
                      Uploaded Logo
                    </Typography>
                  </Box>

                  <Box className="d-flex justify-content-center  align-items-center mt-3">
                    <img
                      src={organizationDetails?.logo_url}
                      alt=""
                      height={"90px"}
                      className="d-bock mx-auto "
                    />
                  </Box>
                </Box>
              )}
              {editable === 2 && (
                <Box>
                  <EditLogo
                    uploadUrl={faviconUpdateUrl}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    fieldName={faviconFieldName}
                  />

                  <Box className="mt-3 w-100">
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "16px",
                          color: "#384D6C",
                          fontWeight: "bold",
                          textAlign: "center",
                        },
                      }}
                    >
                      Uploaded Favicon icon
                    </Typography>
                  </Box>

                  <Box className="d-flex justify-content-center  align-items-center mt-3">
                    <img
                      src={organizationDetails?.favicon_url}
                      alt=""
                      height={"90px"}
                      className="d-bock mx-auto "
                    />
                  </Box>
                </Box>
              )}

              {editable === 4 && (
                <Box>
                  <EditLogo
                    uploadUrl={signUpdateUrl}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    fieldName={signFieldName}
                  />

                  <Box className="mt-3 w-100">
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "16px",
                          color: "#384D6C",
                          fontWeight: "bold",
                          textAlign: "center",
                        },
                      }}
                    >
                      Uploaded Signature
                    </Typography>
                  </Box>

                  <Box className="d-flex justify-content-center  align-items-center mt-3">
                    <img
                      src={signature?.signature_url}
                      alt=""
                      height={"90px"}
                      className="d-bock mx-auto "
                    />
                  </Box>
                </Box>
              )}

              {editable === 6 && (
                <Box>
                  <UploadPayment
                    onQrSuccess={onQrSuccess}
                    onQrFailure={onQrFailure}
                  />

                  <Box className="mt-3 w-100">
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "16px",
                          color: "#384D6C",
                          fontWeight: "bold",
                          textAlign: "center",
                        },
                      }}
                    >
                      Uploaded QR
                    </Typography>
                  </Box>

                  <Box className="d-flex justify-content-center  align-items-center mt-3">
                    <Box
                      sx={{
                        bgcolor: "#989696",
                        width: { xs: "120px", md: "175px" },
                        height: { xs: "120px", md: "175px" },
                      }}
                    >
                      <Avatar
                        src={qrDetails?.qr_code_url}
                        sx={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 0,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              {editable === 3 && (
                <Box>
                  <ContactForm
                    initialData={contactsDetails}
                    onContactFormSuccess={handleContactFormSuccess}
                  />
                </Box>
              )}
              {editable === 5 && (
                <Box>
                  <OrganizationDetailsForm
                    initialData={organizationDetails}
                    onDetailsSubmissionSuccess={
                      handleOnDetailsSubmissionSuccess
                    }
                  />
                </Box>
              )}
              {editable === 7 && (
                <Box>
                  <AddAddminStafDetails onSuccess={handleOnsuccessOfAddStaff} />
                </Box>
              )}
              {editable === 9 && (
                <Box>
                  <EditAddminStafDetails
                    initialData={currentStaffObject}
                    onStaffEditSuccess={onStaffEditSuccess}
                  />
                </Box>
              )}
              {editable === 10 && (
                <Box>
                  <ImageUpload
                    uploadUrl={apiStaffImgUrl}
                    fieldName={"profile_pic_file"}
                    onFailure={onFailure}
                    onSuccess={onSuccess}
                  />
                </Box>
              )}
              {editable === 11 && (
                <Box>
                  <EditHostelLaws
                    initialData={hostelLaws}
                    onlawsFormSuccess={handleOnlawsFormSuccess}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default AddminSetting;
