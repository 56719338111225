import {
  Box,
  Avatar,
  Typography,
  Card,
  CardContent,
  CardMedia,
  useTheme,
  Grid,
  Button,
  Paper,
  Modal,
  Divider,
  Popover,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import AddTestimonials from "../../components/Testimonials/AddTestimonials";
import axios from "axios";
import toast from "react-hot-toast";
import Chip from "@mui/material/Chip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageUpload from "../../components/AdminSettings/ImageUpload";
import EditTestimonial from "../../components/Testimonials/EditTestimonial";
import { getUserFromLocalStorage } from "../../service/localstorage";
import { getApiUrl } from "../../utils/getApiUrl";

const user = getUserFromLocalStorage();
const token = user?.token;
const tokenType = user?.tokenType || "Bearer";

function Testimonials() {
  const [isEdit, setIsEdit] = useState();
  const [isHovered, setIsHovered] = useState(null);
  const [currentTestimonial, setCurrentTestimonial] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [allTestimonials, setAllTestimonials] = useState([]);
  const URL = getApiUrl();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const onSuccessOnAddReview = async (response) => {
    if (response) {
      await getAllTestimonials();

      handleClose();
    }
  };

  const onSuccessOnEditReview = async (response) => {
    if (response) {
      await getAllTestimonials();
      setAnchorEl(null);

      handleClose();
    }
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;

  const handleImageUploadSuccess = () => {
    handleClose();
    getAllTestimonials();
  };

  const handleImageUploadFailure = (error) => {
    console.error("Image upload failed:", error);
  };

  const deteleTestimonials = async () => {
    const id = currentTestimonial._id;
    const apiUrl = `${URL}/sitesettings/${id}`;
    const response = await axios.delete(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response) {
      toast.success("Testimonial delete successfully");
      getAllTestimonials();
      handleClosePop();
    } else {
      toast.error("Failed to delete testimonial");
    }
  };

  const getAllTestimonials = async () => {
    const apiUrl = `${URL}/sitesettings/`; // Make sure URL is defined elsewhere in your code
    try {
      const response = await axios.get(apiUrl); // apiUrl should be passed to axios.get
      if (response.status === 200) {
        setAllTestimonials(response.data); // Make sure setAllTestimonials is defined
      } else {
        // Optional: Handle non-200 status here, if needed
        toast.error("Failed to load testimonials: Unexpected status code.");
      }
    } catch (error) {
      console.error("Failed to load testimonials:", error);
    }
  };

  useEffect(() => {
    getAllTestimonials();
  }, []);
  return (
    <>
      <Box
        bgcolor={"#EEEEFF"}
        sx={{
          [theme.breakpoints.up("xs")]: {
            marginLeft: "0px",
            padding: "10px 5px",
            width: "100vw",
            height: "calc(100vh - 64px)",
            overflow: "auto",
          },
          [theme.breakpoints.up("md")]: {
            marginLeft: "265px",
            padding: "15px 10px",
            width: "calc(100vw - 265px)",
            height: "calc(100vh - 64px)",
            overflow: "auto",
          },
        }}
      >
        <Box>
          <p
            className="container"
            style={{ fontSize: "20px", fontWeight: "700", color: "#384D6C" }}
          >
            Testimonials :
          </p>
        </Box>

        <Box sx={{ mx: 3 }}>
          <Paper
            elevation={4}
            sx={{
              [theme.breakpoints.up("xs")]: {
                width: 300,
                height: 300,
                padding: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 3,
              },
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                bgcolor: "#D2D2D2",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                cursor: "pointer",
                borderRadius: 3,
                "&:hover": {
                  bgcolor: "#e1e1e1",
                },
              }}
              onClick={() => {
                setIsEdit(1);
                handleOpen();
              }}
            >
              <AddIcon style={{ fontSize: "120px", color: "#384D6C" }} />
              <Typography
                sx={{ fontSize: "20px", fontWeight: "bold", color: "#384D6C" }}
              >
                Add Testimonial
              </Typography>
            </Box>
          </Paper>
        </Box>
        <Divider sx={{ my: 4 }}>
          <Chip label="Testimonials" size="meduim" />
        </Divider>
        <Box
          className="mb-3 p-2 "
          sx={{
            margin: "auto",
            height: 700,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#c996ff",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "rgba(0,0,0,.05)",
              borderRadius: "4px",
            },
          }}
        >
          <Grid container spacing={4}>
            {allTestimonials.map((data, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    width: 350,
                    height: 500,
                    margin: "0 auto",
                    position: "relative",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={data.image_url}
                    alt={data.name}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      right: "10px",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      handleClick(e);
                      setCurrentTestimonial(data);
                    }}
                  >
                    ...
                  </Box>

                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        gap: 0,
                        mt: 0,
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          display: "inline-block",
                          "&:hover > #editButton": {
                            display: "flex", // Show button on hover
                          },
                        }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <Avatar
                          src={data?.image_url}
                          sx={{ height: 100, width: 100 }}
                        />
                        <IconButton
                          onClick={() => {
                            handleOpen();
                            setIsEdit(2);
                            setCurrentTestimonial(data);
                          }}
                          id="editButton"
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            display: "none",
                            zIndex: 2,
                          }}
                          variant="contained"
                          color="primary"
                        >
                          <Box
                            sx={{
                              bgcolor: "#1d1a1a8a",
                              height: 100,
                              width: 100,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 50,
                            }}
                          >
                            {" "}
                            <EditIcon style={{ color: "white" }} />
                          </Box>
                        </IconButton>
                      </Box>
                    </Box>
                    <Typography gutterBottom variant="h5" component="div">
                      {data.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {data.bio}
                    </Typography>
                    <Typography
                      className="mt-1"
                      variant="body2"
                      color="text.primary"
                    >
                      {data.star_rating} Stars
                    </Typography>
                    <Divider className="my-1">Review</Divider>
                    <Typography variant="body2" color="text.secondary">
                      {data.comment}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Modal onClose={handleClose} open={open}>
        <Box
          sx={{
            [theme.breakpoints.up("xs")]: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#f0f0f0",
              boxShadow: 24,
              padding: "15px",
              fontFamily: "Arial, sans-serif",
              borderRadius: "8px",
              width: "95%",
            },
            [theme.breakpoints.up("md")]: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#f0f0f0",
              boxShadow: 24,
              padding: "20px",
              fontFamily: "Arial, sans-serif",
              borderRadius: "8px",
              width: "60%",
            },
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontSize: {
                  xs: "16px",
                  md: "22px",
                },
                color: "#384D6C",
                fontWeight: "bold",
              }}
            >
              {isEdit === 1 && " Add Review"}
              {isEdit === 2 && " Edit Testimonial Profile Image "}
              {isEdit === 3 && " Edit Review "}
            </Typography>

            <Box className="d-flex justify-content-center">
              {isEdit === 1 && (
                <AddTestimonials onSuccess={onSuccessOnAddReview} />
              )}

              {isEdit === 2 && (
                <Box className="mt-3">
                  <ImageUpload
                    uploadUrl={`${URL}/sitesettings/testimonials/${currentTestimonial?._id}/image`}
                    headers={{
                      "Content-Type": "application/json",
                      Authorization: `${tokenType} ${token}`,
                    }}
                    fieldName={"image_file"}
                    onFailure={handleImageUploadFailure}
                    onSuccess={handleImageUploadSuccess}
                  />
                </Box>
              )}
              {isEdit === 3 && (
                <EditTestimonial
                  onSuccess={onSuccessOnEditReview}
                  currentData={currentTestimonial}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
      <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid container sx={{ height: 50, padding: "0 10px" }}>
          <Grid
            item
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="edit"
              sx={{ color: "#384D6C", "&:hover": { color: "#000" } }}
            >
              <EditIcon
                onClick={() => {
                  setIsEdit(3);
                  handleOpen();
                }}
              />
            </IconButton>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="edit"
              sx={{ color: "#384D6C", "&:hover": { color: "#000" } }}
            >
              <DeleteIcon onClick={deteleTestimonials} />
            </IconButton>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

export default Testimonials;
